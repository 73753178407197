import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Login } from '../login/login';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public log: Login = new Login();

  constructor() { }

  ngOnInit(): void {
  }

  login(){
    console.log('submiting')
    
      //this.router.navigate(['/inicio']);
      window.open(environment.rutaDashboard, '_blank');
    	

  }

}
