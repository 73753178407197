import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ofertaeducativam',
  templateUrl: './ofertaeducativam.component.html',
  styleUrls: ['./ofertaeducativam.component.css']
})
export class OfertaEducativamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
