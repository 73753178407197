<app-header></app-header>

<section class="page-title bg-title-cursdip overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>CURSOS Y DIPLOMADOS DIPI</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
					<li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
					<li class="breadcrumb-item active">DIPI</li>
				</ol>
			</div>
		</div>
	</div>
</section>
<block-ui>

	<section id="image-block" class="image-block p-5px">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-6 ts-padding"
					style="height:490px;background:url(./assets/images/cursodip1.jpg) 65% 65% / cover no-repeat;">
				</div>
				<div class="col-md-6 ts-padding img-block-right">
					<div class="img-block-head text-left">
						<h3>Cursos y Diplomados</h3>
						<br>
						<p>En DIPI queremos que amplíes tus conocimientos y adquieras nuevas habilidades dentro de la
							Investigación.
							<br>
							Por ello, te ofrecemos cursos y diplomados que fortalecerán tus conocimientos y
							te darán herramientas para un mejor desarrollo profesional.
						</p>
					</div>

					<div class="gap-30"></div>

					<!-- 				<div class="image-block-content">
					<span class="feature-icon float-left"><i class="fa fa-university"></i></span>
					<div class="feature-content">
						<h3>PowerPack Theme</h3>
						<p>Proin gravida nibh vel velit auctor Aenean sollicitudin adipisicing</p>
					</div>
				</div>


				<div class="image-block-content">
					<span class="feature-icon float-left"><i class="fa fa-street-view"></i></span>
					<div class="feature-content">
						<h3>Day Night Support</h3>
						<p>Simply dummy text and typesettings industry has been the industry</p>
					</div>
				</div> -->
					<!--/ End 1st block -->
					<!-- 		<p class="animated4"><a href="#" class="btn btn btn-outline-dark dark">Conoce Mas</a></p> -->
				</div>
			</div>
		</div>
	</section>

	<div class="row">
		<div class="col-lg-12 col-md-12">
			<!-- Portfolio start -->
			<section id="portfolio" class="portfolio mb-20px">
				<div class="container">
					<div class="row">
						<div class="col-lg-5 heading text-lg-left text-center">
							<span class="title-icon classic"><i class="fa fa-university"></i></span>
							<h2 class="title classic">Diplomados</h2>
						</div>
					</div>
				</div>

				<div>
					<div class="text-center" *ngIf="!noConvo">
						No se encontraron convocatorias
					</div>
				</div>

				<div class="container-fluid">
					<div class="row isotope" id="isotope">
						<div class="col-sm-6 col-md-4 col-lg-3 web-design isotope-item"
							*ngFor="let item of convocatorias">
							<div class="grid">

								<ng-container *ngIf="item.conv_imagenHashCode != null; else internalBlock">
									<figure class="m-0 effect-oscar">
										<img class="centered-and-cropped" width="200" height="400"
											src="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ item.vcon_convid }}"
											alt="" target="_blank">
										<figcaption>
											<h3>{{item.vcon_descprog}}</h3>
											<a class="link icon-pentagon"
												[routerLink]="['/programainfo',item.vcon_convid]" target="_blank"><i
													class="fa fa-link"></i></a>
											<a class="view icon-pentagon" data-rel="prettyPhoto"
												href="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ item.vcon_convid }}"
												target="_blank"><i class="fa fa-search"></i></a>
										</figcaption>
									</figure>

								</ng-container>

								<ng-template #internalBlock>

									<figure class="m-0 effect-oscar">
										<img class="centered-and-cropped" width="200" height="400"
											src="./assets/images/notfound.jpg" alt="">
										<figcaption>
											<h3>{{item.vcon_descprog}}</h3>
											<a class="link icon-pentagon"
												[routerLink]="['/programainfo',item.vcon_convid]" target="_blank"><i
													class="fa fa-link"></i></a>
											<a class="view icon-pentagon" data-rel="prettyPhoto"
												href="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ item.vcon_convid }}"
												target="_blank"><i class="fa fa-search"></i></a>
										</figcaption>
									</figure>


								</ng-template>

							</div>
						</div><!-- Isotope item end -->
					</div>
					<!-- Content row end -->
				</div><!-- Container end -->
			</section><!-- Portfolio end -->
		</div>



		<!-- Parallax 1 start -->
		<div class="col-lg-12 col-md-12">
			<div class="hr5" style="margin-top:25px; margin-bottom:25px;"></div>
			<section class="parallax parallax7 mt-20px">
				<div class="parallax-overlay"></div>
				<div class="container">
					<div class="row">
						<div class="col-md-12 text-center">
							<h2>Te interesa alguno de los cursos o diplomados?</h2>
							<h3>Da click para dirigirte a SIPU, e inicia tu proceso de inscripción </h3>
							<p>
								<a href="#" class="btn btn-outline-white mr-2 " [routerLink]="['/sipu']">Conoce los
									pasos a seguir</a>
								<a href="#" class="btn btn-outline-danger text-white" href="https://www.sipu.ujed.mx/">
									Sipu</a>
							</p>
						</div>
					</div>
				</div><!-- Container end -->
			</section><!-- Parallax 1 end -->
		</div>

		
		</div>

		<!-- Clients start -->
		<div class="col-lg-12 col-md-12">
			<div class="hr5" style="margin-top:25px; margin-bottom:25px;"></div>
			<section id="clients" class="clients">
				<div class="container">
					<div class="row wow fadeInLeft">
						<div id="client-carousel" class="col-sm-12 owl-carousel owl-theme text-center client-carousel">
							<figure class="m-0 item client_logo">
								<a href="#">
									<img src="./assets/images/pages/logo-anuies.png" alt="client">
								</a>
							</figure>
							<figure class="m-0 item client_logo">
								<a href="#">
									<img src="./assets/images/pages/logo-comepo.png" alt="client">
								</a>
							</figure>
							<figure class="m-0 item client_logo">
								<a href="#">
									<img src="./assets/images/pages/logo-conacyt.png" alt="client">
								</a>
							</figure>
							<figure class="m-0 item client_logo">
								<a href="#">
									<img src="./assets/images/pages/logo-cumex.png" alt="client">
								</a>
							</figure>
							<figure class="m-0 item client_logo">
								<a href="#">
									<img src="./assets/images/pages/logo-prodep.png" alt="client">
								</a>
							</figure>
						</div><!-- Owl carousel end -->
					</div><!-- Main row end -->
				</div>
				<!--/ Container end -->
			</section>
			<!--/ Clients end -->
	</div>

</block-ui>

<app-footer></app-footer>