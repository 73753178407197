import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politicasreglamentos',
  templateUrl: './politicasreglamentos.component.html',
  styleUrls: ['./politicasreglamentos.component.css']
})
export class PoliticasreglamentosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

}
