import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SipuService } from '../services/sipu.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Destadisticas } from '../models/destadisticas';

@Component({
  selector: 'app-resultadosdipi',
  templateUrl: './resultadosdipi.component.html',
  styleUrls: ['./resultadosdipi.component.css']
})
export class ResultadosdipiComponent implements OnInit {
  estadisticas: { [key: string]: Destadisticas } = {};
  @BlockUI() blockUI: NgBlockUI;

  constructor(private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.blockUI.start('Cargando resultados');
    this._ss.listarEstadisticasDipi().subscribe(estadisticas => {
      this.estadisticas = estadisticas.reduce((map, obj) => (map[obj.dest_estid] = obj, map), {}); 
      console.log("Estadisticas", this.estadisticas);
      this.blockUI.stop(); 
    },
    error => {
      console.log(error);
    });
  }

}
