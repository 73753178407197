import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SipuService } from 'src/app/services/sipu.service';

@Component({
  selector: 'app-investigadoresm',
  templateUrl: './investigadoresm.component.html',
  styleUrls: ['./investigadoresm.component.scss']
})
export class InvestigadoresmComponent implements OnInit {
  @ViewChild('slider', { static: false }) slider!: ElementRef;

  investigadores: any[] = [];
  currentIndex = 0;
  isDragging = false; // Para saber si estamos arrastrando
  startX: number = 0; // Posición inicial del mouse

  constructor(private _ss: SipuService) { }

  ngOnInit(): void {
    this._ss.listarInvestigador().subscribe(investigadores => {
      this.investigadores = investigadores;  
      console.log("INVESIGADORES", this.investigadores);
    },
    error => {
      console.log(error);
    });
  }

  next() {
    this.currentIndex++;
    // Si llegamos al final, volver a la primera imagen sin mostrar espacio en blanco
    if (this.currentIndex >= this.investigadores.length) {
      this.currentIndex = 0; // Reiniciar al principio
    }
    this.updateSlider();
}

prev() {
    this.currentIndex--;
    // Si estamos antes de la primera imagen, ir a la última imagen
    if (this.currentIndex < 0) {
      this.currentIndex = this.investigadores.length - 1; // Ir a la última imagen
    }
    this.updateSlider();
}

  updateSlider() {
    const offset = -this.currentIndex * 250; // Ajustar según el tamaño de la imagen
    this.slider.nativeElement.style.transform = `translateX(${offset}px)`;
  }

  startDrag(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.clientX; // Guarda la posición inicial del mouse
  }

  endDrag(event: MouseEvent) {
    if (!this.isDragging) return;
    
    const endX = event.clientX; // Posición final del mouse
    const distance = this.startX - endX; // Calcula la distancia arrastrada

    // Cambia de imagen según la distancia arrastrada
    if (distance > 50) { // Umbral para "next"
      this.next();
    } else if (distance < -50) { // Umbral para "prev"
      this.prev();
    }

    this.isDragging = false; // Resetea el estado de arrastre
  }

  onDrag(event: MouseEvent) {
    if (!this.isDragging) return;
    
    // Prevenir la selección de texto mientras se arrastra
    event.preventDefault();
  }

  ngAfterViewInit() {
    const sliderElement = this.slider.nativeElement;
 /*    sliderElement.addEventListener('wheel', (event: WheelEvent) => {
        if (event.deltaY > 0) {
            this.next();
        } else {
            this.prev();
        }
        event.preventDefault(); // Evitar el desplazamiento de la página
    }); */

    // Eventos de arrastre
    sliderElement.addEventListener('mousedown', this.startDrag.bind(this));
    sliderElement.addEventListener('mouseup', this.endDrag.bind(this));
    sliderElement.addEventListener('mouseleave', this.endDrag.bind(this));
    sliderElement.addEventListener('mousemove', this.onDrag.bind(this));
  }
}
