<app-header></app-header>


<section class="page-title bg-title-ident overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>IDENTIDAD INSTITUCIONAL</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
				  <li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
				  <li class="breadcrumb-item active">Identidad</li>
				</ol>
			</div>
		</div>
	</div>
</section>


<div class="container">
	<!-- Company Profile -->
	<div class="row">
		<div class="col-md-12 heading text-center">
			<h2 class="title2">IDENTIDAD INSTITUCIONAL
				<span class="title-desc">Quiénes somos</span>
			</h2>
		</div>
	</div><!-- Title row end -->

	<div class="row about-wrapper-top flex-column-reverse flex-lg-row">
		<div class="col-lg-6 ts-padding about-message">
			<h3>PROPÓSITO</h3>
			<p>Contribuir al desarrollo integral de los profesionistas dentro de la cultura científica 
				y del posgrado de más alto nivel de nuestro país, facilitando a los Institutos y Facultades 
				adscritos a la UJED herramientas para la optimización de los recursos financieros, humanos y 
				administrativos derivados de la gestión del posgrado y la investigación que se desarrolle en sus entornos, 
				contribuyendo así a la propuesta de soluciones de las diversas problemática sociales y 
				el desarrollo de propuestas de calidad, participando así con consolidación de nuestra Institución 
				como una de las mejores de México.</p>
		</div>
		<!--/ About message end -->
		<div class="col-lg-6 ts-padding about-img"
			style="height:374px;background:url(./assets/images/pages/proposito.JPG) 50% 50% / cover no-repeat;">
		</div>
		<!--/ About image end -->
	</div>
	<!--/ Content row end -->

	<div class="row about-wrapper-bottom mt-5 mt-lg-0">
		<div class="col-lg-6 ts-padding about-img"
			style="height:374px;background:url(./assets/images/pages/mision.JPG) 50% 50% / cover no-repeat;">
		</div>
		<!--/ About image end -->
		<div class="col-lg-6 ts-padding about-message">
			<h3>MISIÓN</h3>
			<p>Ser una dirección eficaz con el manejo de los distintos recursos de Posgrado e Investigación, 
				manteniendo una actualización permanente de la información derivada de los mismos, así como 
				facilitar el desarrollo de planes de estudio de calidad, creando alianzas, redes y convenios 
				para estimular la vinculación académica y la innovación entre los diferentes sectores sociales, 
				productivos y académicos de la sociedad.
			</p>
<!-- 			<ul class="unstyled arrow">
				<li><a href="#">Etiam porta sem malesuada</a></li>
				<li><a href="#">Pellentesque ornare sem lacinia</a></li>
				<li><a href="#">Cras mattis consectetur purus</a></li>
				<li><a href="#">Sed hendrerit quam sed ante</a></li>
			</ul> -->
		</div>
		<!--/ About message end -->
	</div>

	<div class="row about-wrapper-top flex-column-reverse flex-lg-row">
		<div class="col-lg-6 ts-padding about-message">
			<h3>VISIÓN</h3>
			<p>
				Contar con un sistema de Investigación y Posgrado administrados de manera ágil, 
				eficiente y automatizada, sustentada en normas y procedimientos actualizados manteniendo 
				al personal capacitado para la gestión, integración y desempeño de sus labores.</p>
		</div>
		<!--/ About message end -->
		<div class="col-lg-6 ts-padding about-img"
			style="height:374px;background:url(./assets/images/pages/vision.JPG) 50% 50% / cover no-repeat;">
		</div>
		<!--/ About image end -->
	</div>

	<div class="row about-wrapper-bottom mt-5 mt-lg-0">
		<div class="col-lg-6 ts-padding about-img"
			style="height:374px;background:url(./assets/images/pages/valores.JPG) 50% 50% / cover no-repeat;">
		</div>
		<!--/ About image end -->
		<div class="col-lg-6 ts-padding about-message">
			<h3>VALORES</h3>
			<p>La Dirección Institucional de Posgrado e Investigación de Posgrado e Investigación refuerza sus valores 
				a través de sus actividades, redes de colaboración y la gestión en las actividades universitarias.
				<br> - Responsabilidad
				<br> - Integridad
				<br> - Proactividad
				<br> - Honestidad
				<br> - Respeto
				<br> - Tolerancia
			</p>
<!-- 			<ul class="unstyled arrow">
				<li><a href="#">Etiam porta sem malesuada</a></li>
				<li><a href="#">Pellentesque ornare sem lacinia</a></li>
				<li><a href="#">Cras mattis consectetur purus</a></li>
				<li><a href="#">Sed hendrerit quam sed ante</a></li>
			</ul> -->
		</div>
		<!--/ About message end -->
	</div>

	<div class="row about-wrapper-top flex-column-reverse flex-lg-row">
		<div class="col-lg-6 ts-padding about-message">
			<h3>OBJETIVOS</h3>
			<p>
				- Brindar un servicio eficiente y oportuno haciendo uso de las diferentes herramientas que nos brinda la UJED.
				<br> - Propiciar espacios de encuentro a fin de fomentar el trabajo colaborativo e interinstitucional.
				<br> - Apoyar a las diferentes jefaturas de posgrado a consolidar sus planes de estudio y a la mejora constante de sus procesos.
				<br> - Dar difusión a las convocatorias publicadas por los diferentes organismos, estatales, nacionales e internacionales.
				<br> - Ser un facilitador para que el profesor investigador obtenga el recurso financiero para el desarrollo del proyecto.
			</p>
		</div>
		<!--/ About message end -->
		<div class="col-lg-6 ts-padding about-img"
			style="height:374px;background:url(./assets/images/pages/objetivos.JPG) 50% 50% / cover no-repeat;">
		</div>
		<!--/ About image end -->
	</div>

	
	<!--/ Content row end -->
	<!-- Company Profile -->
</div>
<!--/ 1st container end -->
<div class="gap-60"></div>
<app-footer></app-footer>