export const environment = {
  production: true,

  COOKIE_NAME:'userDataPORTALDIPI',

/*   rutaDashboard: 'https://sistemas.ujed.mx/dashboarddipi/#/', */

  rutaDashboard: 'https://sistemas.ujed.mx/dashboarddipi/#/dashboard',

  rutaAPISipu: 'https://www.sipu.ujed.mx/api/api/sipu',

};
