<app-header></app-header>
<!-- <block-ui> -->
<block-ui>
	<section class="page-title bg-title-noticias overlay-fondo">
		<div class="container">
			<div class="row">
				<div class="col-12 text-left">
					<div class="title">
						<h2>{{convocatoria?.dcex_titulo}} </h2>
					</div>
					<ol class="breadcrumb justify-content-left p-0 m-0">
						<li class="breadcrumb-item"><a [routerLink]="['/convoexterno']">Regresar</a></li>
						<li class="breadcrumb-item active"> . </li>
					</ol>
				</div>
			</div>
		</div>
	</section>

	<!-- Portfolio item start -->
	<div class="container">
		<div class="row">
			<div class="col-md-12 heading text-center">
				<h2 class="titleConv">{{convocatoria?.dcex_titulo}}
					<span class="title-desc"> - </span>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-5 col-xs-12 mt-1" *ngIf="convocatoria.not_imagenHashCode" >
				<img src="https://www.sipu.ujed.mx/api/api/sipu/dconvexternas/img/{{ convocatoria?.dcex_convid }}" style="width: 100%;"/>
			</div>
			<div class="col-lg-5 col-xs-12 mt-1" *ngIf="!convocatoria.not_imagenHashCode">
				<img src="./assets/images/noimagenn.png" alt="post-image" style="height: 40%;" class="img-fluid">
			</div>

			<!-- sidebar start -->
			<div class="col-lg-7 col-xs-12 mt-1">

				<div class="sidebar">
					<div class="portfolio-desc">
						
						<h4>Descripción: </h4>
						<div class="post-details">
	
							<div [innerHTML]="convocatoria?.dcex_descrip"></div>
							<br />
							
						</div>

						<h4>Objetivo: </h4>
						<p>{{convocatoria?.dcex_objetivo}}</p> <br/>

						<h5>Enlace: </h5>
						<p>
							<a> {{convocatoria?.dcex_enlace}} </a></p> <br/>
								<div *ngIf="not_documentoHashCode">
									<b>Conoce más información en el siguiente documento</b>
								<div class="col-md-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
									<div class="plan text-center featured">
									<p class="plan-price"><i class="fa fa-file-pdf-o"></i></p>
			
									<a class="btn btn-danger" (click)="getDocFromService(urlEndPoint + '/dconvexternas/doc/');">Descarga el documento</a>
									</div>
								</div>
								</div>
					</div>
				</div>
			</div>
			<!-- sidebar end -->
		</div><!-- Portfolio item row end -->
	</div><!-- Container end -->

	<div class="hr1" style="margin-bottom:50px;"></div>
	
	<block-ui>
		<!-- </block-ui> -->
		<app-footer></app-footer>