    <section class="d-flex align-items-center justify-content-center" style="height: 100vh;">
        <div class="container">
          <div class="row">
            <div class="col-lg-11 mx-auto">
              <div class="signup">
                <div class="row">
                  <div class="col-md-6 signup-greeting overlay"
                    style="background-image: url(assets/images/fondo.jpg);">
      
                  </div>
                  <div class="col-md-6">
                    <div class="signup-form">
                      <form class="row" >
                          <div class="col-md-12">  
                             <div class="col-md-12">
                              <img class="img-fluid justify-content-center" src="./assets/images/ujed1.png" style="max-width: 50%;"
                              alt="logo">
                            <h4> Dirección Institucional
                              de Posgrado e investigación</h4>
                        </div>
                          <input [disabled]="isLoading" type="text"
                            class="form-control border border-gray-color"
                            name="user" placeholder="Usuario" autocomplete="off"
                            
                            >
                        </div>
                        <div class="col-lg-12">
                          <input [disabled]="isLoading" type="password"
                            class="form-control border border-gray-color" 
                            name="password" placeholder="Contraseña" autocomplete="off">
                        </div>
                        <div class="col-sm-6">
                         <!--  <button type="submit" class="btn btn-primary btn-sm"
                            [disabled]="isLoading">
      
                            <ng-template [ngIf]="!isLoading" [ngIfElse]="notShow">
                              <span>
                                Entrar
                              </span>
                            </ng-template>
                            <ng-template #notShow>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              <span class="sr-only">Loading...</span>
                              <span>Cargando...</span>
                            </ng-template>
                          </button> -->




                          <a (click)='login()'>
                            <button type="submit" class="btn btn-primary btn-sm"
                            [disabled]="isLoading">

                            <ng-template [ngIf]="!isLoading" [ngIfElse]="notShow">
                              <span> Entrar </span>
                            </ng-template>
                            <ng-template #notShow>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              <span class="sr-only">Loading...</span>
                              <span>Cargando...</span>
                            </ng-template>
                          </button>
                          </a>


      
                        </div>
      
                        <div class="justify-content-space-around" style="text-align:justify">
      
                          <div class="hr5" style="margin-top:45px; margin-bottom:45px;"></div>
                         <p><a  target="_blank">¿Olvidaste tu contraseña?</a></p>
      
                        </div>
      
                       <!--  <div class="justify-content-space-around" style="text-align:justify">
                          <div class="hr5" style="margin-top:45px; margin-bottom:45px;"></div>
                          <p>Si no tienes cuenta, tramitarla en el <a
                              href="https://sistemas.ujed.mx/">Departamento de Sistemas</a>,
                            en el apartado: "Solicitud de servicios"</p>
                        </div> -->
      
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </section>
      