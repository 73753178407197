<section id="image-block" class="image-block p-0">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-5 ts-padding overlayOA"
				style="height:450px;
				background:url(./assets/images/mainpage/ofeduca.png) 90% 90% / cover;">
			</div>
			<div class="col-md-7 ts-padding img-block-right">
				<div class="img-block-head text-left">
					<h3>OFERTA EDUCATIVA</h3>
					<p>Atrévete a dar el siguiente paso, conoce la propuesta educativa que tenemos para ti. <br>
						¡Ven y cumple tu sueño!
					</p>

					<div class="gap-30"></div>

					<div class="image-block-content">
						<span class="feature-icon float-left"><i class="fa fa-university"></i></span>
						<div class="feature-content">
						</div>
					</div>

					<h2>CONVOCATORIAS POSGRADO</h2>
					<div class="gap-30"></div>
					<!--/ End 1st block -->

					<div class="image-block-content">
						<span class="feature-icon float-left"><i class="fa fa-street-view"></i></span>
<!-- 						<div class="feature-content">
						</div> -->
					</div>

					<h2>CURSOS Y DIPLOMADOS</h2>
				</div>

				<div class="gap-30"></div>
				<p class="animated4"><a [routerLink]="['/ofertaeduca']" class="btn btn btn-outline-dark dark">Conoce
						Mas</a></p>
			</div>
		</div>
	</div>
</section>