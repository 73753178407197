import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SipuService } from '../services/sipu.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-cursosdiplomados',
  templateUrl: './cursosdiplomados.component.html',
  styleUrls: ['./cursosdiplomados.component.css']
})
export class CursosdiplomadosComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  convocatorias: any;
  noConvo:boolean=false;

  constructor(private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.blockUI.start('Cargando Diplomados ...');
    this._ss.listarDipi().subscribe(convocatorias => {
      this.convocatorias = convocatorias;  
      console.log("DIPLOMADOS:****", convocatorias);
      if (this.convocatorias.length>0) {
        this.noConvo=true;

      }
       this.blockUI.stop(); 
    },
    error => {
      console.log(error);
    });
  


  }

}
