<block-ui>

	<app-header></app-header>

	<section class="page-title bg-title-noticias overlay-fondo">
		<div class="container">
			<div class="row">
				<div class="col-12 text-left">
					<div class="title">
						<h3>{{noticia?.dnot_titulo}}</h3>
					</div>
					<ol class="breadcrumb justify-content-left p-0 m-0">
						<li class="breadcrumb-item"><a [routerLink]="['/noticias']">Regresar</a></li>
						<li class="breadcrumb-item active">NotiEgresados</li>
					</ol>
				</div>
			</div>
		</div>
	</section>

	<!-- Portfolio item start -->
	<section id="portfolio-item">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-xs-12 news">
					<div class="block mt-1">
						<!-- Article -->
						<article class="blog-post single">
							<div class="post-content">

								<div class="col-lg-12 col-xs-12">
									<div class="row">
									<div class="col-lg-3 col-xs-12">
										<div class="date">
											<h4><span>{{noticia?.dnot_fec}}</span></h4>
										</div>
										<div class="post-title mt-5">
											<h3>{{noticia?.dnot_titulo}}</h3>
										</div>
									</div>
									<div class="col-lg-9 col-xs-12 portfolio-item">
										<div class="portfolio-slider mb-1 mb-lg-0">
											<div *ngIf="!this.tieneImagenes">
												<img src="./assets/images/banner/fondo11.jpg" alt="post-image" class="img-fluid">
											</div>
											<div id="main-slide" class="carousel slide" data-ride="carousel" *ngIf="this.tieneImagenes">
												<div class="carousel-inner" style="height: 400px;">
													<div class="carousel-item "
														*ngFor="let item of detalle;let index = index;let isFirst = first"
														[ngClass]="{active:isFirst}">
														<img [src]="urlEndPoint + '/dnoticiasportal/banners/img/' + item.dban_bannid "
															alt="item.dban_bannid" style="width: 100%;
															height: 100%;
															object-fit: cover;" />
													</div>
												</div>
					
												<a class="left carousel-control carousel-control-prev" href="#main-slide" data-slide="prev">
													<span><i class="fa fa-angle-left"></i></span>
												</a>
												<a class="right carousel-control carousel-control-next" href="#main-slide"
													data-slide="next">
													<span><i class="fa fa-angle-right"></i></span>
												</a>
					
											</div>
										</div>
									</div>
								</div>
							</div>
							
								<div class="post-meta mb-3">
									<ul class="list-inline">
										<li class="list-inline-item">
											<i class="fa fa-user-o"></i>
											<a> {{noticia?.dnot_autor}} </a>
										</li>
										<li class="list-inline-item">
											<i class="fa fa-hashtag"></i>
											<a> Egresados </a>
										</li>
									</ul>
								</div>
								<div class="post-details">
	
									<div [innerHTML]="noticia?.dnot_descrip"></div>
									<br />
<!-- 									<div class="quotes">
										<blockquote>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim veniam quis nostrud.laboris nisi ut aliquip ex ea commodo consequat. </blockquote>
									</div> -->
									
								</div>
							</div>
						</article>
						<!-- Comment Section -->
					</div>
				</div>
				<!-- sidebar end -->
				<!-- Portfolio item slider end -->
			</div><!-- Portfolio item row end -->
		</div><!-- Container end -->
	</section><!-- Portfolio item end -->




<!-- 	
<section class="news section">
	<div class="container">
		<div class="row mt-30">
			<div class="col-lg-6 col-md-10 mx-auto">
				<div class="block">
					<article class="blog-post single">
						<div class="post-thumb">
							<img src="./assets/images/single-post.jpg" alt="post-image" class="img-fluid">
						</div>
						<div class="post-content">
							<div class="date">
								<h4><span>{{noticia?.dnot_fec}}</span></h4>
							</div>
							<div class="post-title">
								<h3>{{noticia?.dnot_titulo}}</h3>
							</div>
							<div class="post-meta mb-3">
								<ul class="list-inline">
									<li class="list-inline-item">
										<i class="fa fa-user-o"></i>
										<a>{{noticia?.dnot_autor}}</a>
									</li>
									<li class="list-inline-item">
										<i class="fa fa-heart-o"></i>
										<a href="#">350</a>
									</li>
								</ul>
							</div>
							<div class="post-details">

								<div [innerHTML]="noticia?.dnot_descrip"></div>
								<br />
								<div class="quotes">
									<blockquote>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim veniam quis nostrud.laboris nisi ut aliquip ex ea commodo consequat. </blockquote>
								</div>
								
							</div>
						</div>
					</article>
				</div>
			</div>
		</div>
	</div>
</section> -->


<!--=============================
=            Gallery            =
==============================-->

	<div class=" gallery-full pb-0 container-fluid p-0">
		<div class="row">
			<div class="col-12">
				<div class="section-title">
					<h3> <span class="alternate">Galería </span> de fotos</h3>
				</div>
			</div>
		</div>
		<div class="row no-gutters">
			<!-- Gallery Image -->
			<div class="col-lg-3 col-md-4" *ngFor="let item of detalle">
				<div class="image">
					<img [src]="urlEndPoint + '/dnoticiasportal/banners/img/' + item.dban_bannid "alt="item.dban_bannid" 
					style="width: 100%; height: 40vh; object-fit: cover;" />

					<!-- <img src="images/gallery/gallery-full-one.jpg" alt="gallery-image" class="img-fluid"> -->
					<div class="primary-overlay">
						<a class="image-popup" data-effect="mfp-with-zoom" href="images/gallery/gallery-full-popup-one.jpg"><i
								class="fa fa-picture-o"></i></a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="hr5" style="margin-top:25px; margin-bottom:25px;"></div>

	<app-footer></app-footer>
</block-ui>