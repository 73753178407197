<!-- Header start -->
<header id="header" class="fixed-top header" role="banner">
  <!-- <a class="navbar-brand navbar-bg" href="index.html"><img class="img-fluid float-right" src="images/logo.png" alt="logo"></a> -->
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-lg navbar-dark">
          <a class="navbar-brand navbar-bg" href="index.html">
            <img class="img-fluid float-center logo-white" src="./assets/images/logoujed2.png" style="max-width: 50%;"
              alt="logo">
            <img class="img-fluid float-center logo-default" src="./assets/images/ujed1.png" style="max-width: 70%;"
              alt="logo">
          </a>
          <button class="navbar-toggler ml-auto rounded-0 text-white" type="button" data-toggle="collapse"
            data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-bars"></span>
          </button>

          <button class="navbar-toggler rounded-0 text-white" (click)='login()'>
            <i class="fa fa-key" aria-hidden="true"></i>
          </button>

          <div class="collapse navbar-collapse text-center" id="navigation">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link"[routerLink]="['/ofertaeduca']"> OFERTA EDUCATIVA </a>
                <!--  <div class="dropdown-menu">
                  <a class="dropdown-item" href="about.html">About Us</a>
                  <a class="dropdown-item" href="service.html">Services</a>
                  <a class="dropdown-item" href="career.html">Career</a>
                  <a class="dropdown-item" href="testimonial.html">Testimonials</a>
                  <a class="dropdown-item" href="faq.html">Faq</a>
                </div> -->
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown" [routerLink]="['/sipu']" role="button" aria-haspopup="true" 
                  aria-expanded="false"> SIPU </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="https://www.sipu.ujed.mx/"> Sipu.ujed.mx</a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" [routerLink]="['/cursosdiplomados']" role="button" aria-haspopup="true"
                  aria-expanded="false"> CURSOS Y DIPLOMADOS
                </a>
              </li>

              <li class="nav-item dropdown">
                <a class="nav-link"  [routerLink]="['/citaapa']"  role="button" aria-haspopup="true"
                  aria-expanded="false">
                  HERRAMIENTAS
                </a>
              <!--   <div class="dropdown-menu">
                  <a class="dropdown-item" href="blog-rightside.html">Blog with Sidebar</a>
                  <a class="dropdown-item" href="blog-item.html">Blog Single</a>
                </div> -->
              </li>

<!--               <li class="nav-item dropdown">
                <a class="nav-link"  [routerLink]="['/investigadores']"  role="button" aria-haspopup="true"
                  aria-expanded="false">
                  INVESTIGADORES
                </a>
              </li> -->
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                  aria-expanded="false" [routerLink]="['/identidad']"> SOBRE NOSOTROS 
                </a>

                <div class="dropdown-menu">
                  <a class="dropdown-item" [routerLink]="['/identidad']" >Identidad</a>
                  <a class="dropdown-item" [routerLink]="['/politicasreglamentos']"> Politicas y reglamentos</a>
                  <a class="dropdown-item" [routerLink]="['/directorio']"> Directorio</a>
<!--                   <a class="dropdown-item"  (click)='login()'> Login</a> -->
                </div>
              </li> 
<!--               <a [routerLink]="['/login']" class="ticket">
              <span><i class="fa fa-key" aria-hidden="true"></i></span>
            </a> -->
            </ul>
            <a  (click)='login()' class="key">
              <span><i class="fa fa-key" aria-hidden="true" alt="key"></i></span>
            </a >
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!--/ Header end -->