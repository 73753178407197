<app-header></app-header>


<section class="page-title bg-title-invest overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title"  *ngFor="let inf of info">
					<h2>{{inf?.PVIS_NOMBRE}}</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
				    <li class="breadcrumb-item"><a [routerLink]="['/investigadores']">Regresar</a></li>
				  <li class="breadcrumb-item active">Investigador</li>
				</ol>
			</div>
		</div>
	</div>
</section>


<!--================================
=            Page Title            =
=================================-->

<section class="section single-speaker">
	<div class="container">
		<div class="block">
			<div class="row">
				<div class="col-lg-7 col-md-6 align-self-center" >
					<div class="content-block">
						<div class="name" *ngFor="let inf of info">
							<h3>{{inf?.PVIS_NOMBRE}}</h3>		
						</div>
						<div class="profession">
							<p>Investigador</p>
						</div>	
						<div class="details">						
						<div [innerHTML]="investigador?.dinv_descrip"></div>
					</div>
						<br />

					</div>
				</div>
				<div class="col-lg-5 col-md-6 align-self-md-center">
					<div class="image-block">
						<img src="https://www.sipu.ujed.mx/api/api/sipu/dinvestigadores/img/{{ investigador?.dinv_invid }}" style="width: 100%;"/>
					</div>
				</div>
			</div>
		</div>
		<div class="block-2">
			<div class="row">
				<div class="col-md-6">
					<div class="personal-info" *ngFor="let inf of info">
						<h5><b>Información Personal</b></h5>
						
						<ul class="m-0 p-0">
							<li> <p> <b>MATRICULA: </b> {{inf?.PVIS_MATRICULA}}</p> </li>
							<li> <p> <b>PERTENCE AL SISTEMA NACIONAL DE INVESTIGADORES (SNI): </b> {{inf?.PVIS_NIVEL_DESCRIP}}</p></li>
							<li *ngFor="let c of contrato">
								<p><b>UNIDAD ACADÉMICA DE ADSCRIPCIÓN:</b> {{c?.ures}}. </p>
								<b>TIPO DE CONTRATO:</b>  PROFESOR DE {{c?.dedicacion}}
							</li>
						</ul>

						<br> <p> <b>CUERPOS ACADEMICOS</b> </p>
						<ul class="m-0 p-0" *ngFor="let cuerpo of cuerpoAcad">
							<li> <b>Cuerpo: </b> {{cuerpo?.cuerpo}}, <b>Grado:</b> {{cuerpo?.grado}}. <br>
								 Lineas de Investigación: {{cuerpo?.lgac}} .</li>
						</ul>
					</div>
				</div>
				<div class="col-md-6">
					<h5><b>Publicaciones.</b> </h5>
					<div class="skills">
						<h5>Libros</h5>
						<div *ngFor="let libro of libros">
						<!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis repellat inventore at praesentium perspiciatis labore reprehenderit sapiente provident vel maxime.</p> -->
						<div class="skill-bars">
							<!-- SkillBar -->
							<div class="skill-bar">
								<p> ({{libro.ANIO_PB}}). 
									{{libro.CAPITULO_TIT}}.
									<i>{{libro.TITULO}} ({{libro.CAPITULO_NUM}}).</i>  
									{{libro.EDITORIAL}} , {{libro.PAIS_DESC}}.
								</p>	
							</div>
						</div>
					</div>

					<h5>Articulos</h5>
					<div *ngFor="let art of articulos">
					<!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis repellat inventore at praesentium perspiciatis labore reprehenderit sapiente provident vel maxime.</p> -->
					<div class="skill-bars">
						<!-- SkillBar -->
						<div class="skill-bar">
							<p> ({{art.ANIO}}). 
								{{art.TITULO}}.
								<i>{{art.REVISTA}}.</i>  
								{{art.EDITORIAL}} , {{art.PAIS_DESC}}.
							</p>	
						</div>
					</div>
				</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>


<app-footer></app-footer>