<section class="section exaujed bg-speaker overlay-lighter">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Section Title -->
        <div class="section-title white">
          <h3> <b>EGRESADOS UJED</b></h3>
          <p>Conoce las ultimas noticias de los egresados</p>
        </div>
      </div>
    </div>


    <div class="row">

      <div class="col-sm-4 col-md-4 col-lg-4 web-design isotope-item" *ngFor="let item of noticias">
        <div class="grid">
          
          <a [routerLink]="['/exaujedinfo',item.dnot_notid]" target="_blank">
          <figure class="m-0 effect-oscar overlay-exaujed">
            <img class="centered-and-cropped" width="350" height="350" [src]="urlEndPoint + '/dnoticiasportal/img/' + item.dnot_notid + '?not_imagenHashCode=' + item.not_imagenHashCode" alt="">
            
            <figcaption>
              <h3 style="display: inline; position: relative;">{{item.dnot_titulo}} 
                <br><span class="alternate">{{item.dnot_fec}}</span>
              </h3>
              <br>
              <a class="link icon-pentagon" [routerLink]="['/exaujedinfo',item.dnot_notid]"><i class="fa fa-link"></i></a>
            </figcaption>
          </figure>
        </a>

        </div>
      </div><!-- Isotope item end -->
      

    </div>
  </div>
</section>