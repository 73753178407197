import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SipuService } from '../services/sipu.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-exaujedprincipal',
  templateUrl: './exaujedprincipal.component.html',
  styleUrls: ['./exaujedprincipal.component.css']
})
export class ExaujedprincipalComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  notiegresados: any;
  urlEndPoint = `${environment.rutaAPISipu}`;

  constructor(private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {
    window.scroll(0, 0);
/*     
    this.blockUI.start('Cargando notiegresados ...');
    this._ss.listarNoticiasPorFecha().subscribe(notiegresados => {
      this.notiegresados = notiegresados; 

      this.notiegresados = this.notiegresados.filter((n: any) => 
      n.dnot_notiegresados == 'S'); 
       this.blockUI.stop(); 
    },
    error => {
      console.log(error);
    }); */
  
  }

}
