<!-- Start Home Slider -->
<div id="slider">


    <!-- Slider start -->
    <section id="home" class="p-0">
        <div id="main-slide" class="carousel slide" data-ride="carousel">
            <div class="overlay"></div>
            <ol class="carousel-indicators">
                
                <li data-target="#main-slide" data-slide-to="this.numNoticas" class="active" *ngIf="!this.hyaNoticiasActivas"></li>
                <li data-target="#main-slide" data-slide-to="this.numNoticas" *ngIf="this.hyaNoticiasActivas"></li>
                <li data-target="#main-slide" *ngFor="let item of noticias;let index = index"
                    ></li>
                
            </ol>
            <div class="carousel-inner">

                <div class="carousel-item active" *ngIf="!this.hyaNoticiasActivas">
                    <img class="img-fluid" src="./assets/images/mainpage/slider/dipi-img.png" alt="slider">
                    <div class="slider-content">
                        <div style=" position: absolute;bottom: 20px;left: 0;">
                            <div class="col-md-12 text-left">
                                <h3 class="animated2">
                                    Dirección Institucional de<br> Posgrado e investigación 
                                </h3>
                                <h4 class="animated3">
                                    Portal
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="carousel-item" *ngIf="this.hyaNoticiasActivas" [ngClass]="{active:1}">
                    <img class="img-fluid" src="./assets/images/mainpage/slider/dipi-img.png" alt="slider">
                    <div class="slider-content">
                        <div style=" position: absolute;bottom: 50px;left: 0;">
                            <div class="col-md-12 text-left">
                                <h3 class="animated2">
                                    Dirección Institucional de Posgrado e investigación 
                                </h3>
                                <h4 class="animated3">
                                    Portal
                                </h4>
                                <h2 class="animated4"><a [routerLink]="['/noticias']"
                                    class="slider btn btn btn-outline-white white">Ver Noticias</a></h2>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="carousel-item " *ngFor="let item of noticias;let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
                    <img class="img-fluid"
                        [src]="urlEndPoint + '/dnoticiasportal/img/' + item.dnot_notid + '?not_imagenHashCode=' + item.not_imagenHashCode"
                        alt="item.dnot_titulo">
                    <div class="slider-content">

                        <div style=" position: absolute;bottom: 20px;left: 0;">
                            <div class="col-md-12 text-left">
                                <h3 class="animated2">
                                    {{item.dnot_titulo}}
                                </h3>
                                <h4 class="animated3">

                                    {{item.dnot_observaciones}}
                                </h4>
                                <h2 class="animated4"><a [routerLink]="['/noticiainfo',item.dnot_notid]"
                                        class="slider btn btn btn-outline-white white">Conoce
                                        Más</a></h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-item" *ngIf="this.hyaNoticiasActivas" >
                    <img class="img-fluid" src="./assets/images/mainpage/slider/dipi-img.png" alt="slider">
                    <div class="slider-content">
                        <div style=" position: absolute;bottom: 50px;left: 0;">
                            <div class="col-md-12 text-left">
                                <h3 class="animated2">
                                    Dirección Institucional de Posgrado e investigación 
                                </h3>
                                <h4 class="animated3">
                                    Portal
                                </h4>
                                <h2 class="animated4"><a [routerLink]="['/noticias']"
                                    class="slider btn btn btn-outline-white white">Ver Noticias</a></h2>
                            </div>
                        </div>
                    </div>
                </div>
                

                


            </div>
            <a class="left carousel-control carousel-control-prev" href="#main-slide" data-slide="prev">
                <span><i class="fa fa-angle-left"></i></span>
            </a>
            <a class="right carousel-control carousel-control-next" href="#main-slide" data-slide="next">
                <span><i class="fa fa-angle-right"></i></span>
            </a>
        </div>
    </section>
    <!--/ Slider end -->
</div>
<!-- End Home Slider -->