import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SipuService } from '../../services/sipu.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-exaujed',
  templateUrl: './exaujed.component.html',
  styleUrls: ['./exaujed.component.css']
})
export class ExaujedComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  notiegresados: any;
  urlEndPoint = `${environment.rutaAPISipu}`;

  p: number = 1;
  currentPage = 1;

  constructor(private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {
    window.scroll(0, 0);

    
    this.blockUI.start('Cargando notiegresados ...');
    this._ss.listarNoticiasPorFecha().subscribe(notiegresados => {
      this.notiegresados = notiegresados; 

      this.notiegresados = this.notiegresados.filter((n: any) => 
      n.dnot_notiegresados == 'S'); 
       this.blockUI.stop(); 
    },
    error => {
      console.log(error);
    });
  
  }

  totalPages() {
    const pageCount = Math.ceil(this.notiegresados.length / 9);
    const pageArray = [];
    for (let i = 1; i <= pageCount; i++) {
      pageArray.push(i);
    }
    return pageArray;
  }


  goToNextPage() {
    const nextPage = this.currentPage + 1;
    if (this.totalPages().includes(nextPage)) {
      this.currentPage = nextPage;
    }
  }
  
  goToPreviousPage() {
    const prevPage = this.currentPage - 1;
    if (prevPage > 0) {
      this.currentPage = prevPage;
    }
  }
}
