

	<!-- Footer start -->
	<footer id="footer" class="footer">
	  <div class="container">
	    <div class="row">

			<div class="col-md-12 col-lg-6 footer-widget footer-about-us mb-5 mb-lg-0">
				<h3 class="widget-title"> <b>Contáctanos</b></h3>
				<p>Dirección Institucional de Posgrado e Investigación</p>
				<h4>Dirección:</h4>
				<p>Cuauhtémoc 223. Zona Centro. C.P. 34000, Durango, Dgo.</p>
				<div class="row">
				  <div class="col-12">
					<h4>Email:</h4>
					<p><a href="mailto:dipi.servicios@ujed.mx" class="text-white">dipi.servicios@ujed.mx</a></p>
				  </div>
				  <div class="col-12">
					<h4>Telefonó:</h4>
					<p><a href="tel:+(618) 827 1277" class="text-white">(618) 827 1277</a></p>
					<p><a class="text-white">Extensión: 2131</a></p>
				  </div>
				</div>
			  </div>
			  <!--/ end about us -->
	      <div class="col-md-4 col-lg-6 footer-widget footer-about-us mb-5 mb-lg-0">
		<div class="row">
			<div class="col-lg-12 row">
				<div class="col-sx-4 col-md-8 col-8">
					<p>También puedes contactar a la Dirección Institucional de Posgrado e Investigación vía WhatsApp.</p>
				</div>
				
				<div class="col-sx-4 col-md-4 col-4">
					<a href="https://wa.link/9f6mz1">
						<button class="btn btn-sm" style="background-color:#2aa71b">
							<img src="./assets/images/wa.png" width="30vh">
						</button>
					</a>
				</div>

				<div class="col-lg-12 col-md-6 mt-4">
					<p>MAPA</p>
					<div class="embed-responsive embed-responsive-16by9" style="max-width: 550px; max-height: 200px;">
					  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.011247304107!2d-104.6592472846518!3d24.03066853392777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869bb7df36e713c3%3A0x76ebfafcbef42082!2sAv.%20Cuauhtemoc%20223%2C%20Zona%20Centro%2C%2034000%20Durango%2C%20Dgo.!5e0!3m2!1ses!2smx!4v1674960612283!5m2!1ses!2smx"
						class="embed-responsive-item" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
					  </iframe>
					</div>
				  </div>
			</div>
		</div>
	          
	      </div>
	      <!--/ End Recent Posts-->

	    </div><!-- Row end -->
	  </div><!-- Container end -->
	</footer><!-- Footer end -->


	<!-- Copyright start -->
	<section id="copyright" class="copyright angle">
	  <div class="container">
	    <div class="row">
	      <div class="col-md-12 text-center">
	        <ul class="footer-social unstyled">
	          <li>
	            <a title="Twitter" href="https://twitter.com/UJED_Oficial">
	              <span class="icon-pentagon wow bounceIn"><i class="fa fa-twitter"></i></span>
	            </a>
	            <a title="Facebook" href="https://www.facebook.com/dipi.ujed/">
	              <span class="icon-pentagon wow bounceIn"><i class="fa fa-facebook"></i></span>
	            </a>
	            <a title="Google+" href="mailto:dipi.servicios@ujed.mx">
	              <span class="icon-pentagon wow bounceIn"><i class="fa fa-google-plus"></i></span>
	            </a>
				<a class="youtube" href="https://www.youtube.com/channel/UCqQ5i9AnpTMv_uRvJmTsgEw">
					<span class="icon-pentagon wow bounceIn"><i class="fa fa-youtube-play"></i></span>
				</a>
			
	          </li>
	        </ul>
	      </div>
	    </div>
	    <!--/ Row end -->
	    <div class="row">
	      <div class="col-md-12 text-center">
	        <div class="copyright-info">
	          &copy; Copyright 2022 | UJED 
<!-- 			  <span>Designed by <a href="https://themefisher.com">Themefisher.com</a></span> -->
	        </div>
	      </div>
	    </div>
	    <!--/ Row end -->
	    <div id="back-to-top" data-spy="affix" data-offset-top="10" class="back-to-top affix position-fixed">
	      <button class="btn btn btn-outline-white" title="Back to Top"><i class="fa fa-angle-double-up"></i></button>
	    </div>
	  </div>
	  <!--/ Container end -->
	</section>
	<!--/ Copyright end -->