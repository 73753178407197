import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SipuService } from 'src/app/services/sipu.service';
import { environment } from 'src/environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Dconvexternas } from 'src/app/models/dconvexternas';

@Component({
  selector: 'app-convoexternasinfo',
  templateUrl: './convoexternasinfo.component.html',
  styleUrls: ['./convoexternasinfo.component.css']
})
export class ConvoexternasinfoComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  urlEndPoint = `${environment.rutaAPISipu}`;

  id: number;
  acc: string;
  convocatoria: any;
  tieneImagenes:boolean=false;


  private subscription: Subscription;
  not_documentoHashCode: any;

  constructor(private activatedRoute: ActivatedRoute, private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {

    window.scroll(0, 0);
    //this.getProgramas();
    this.blockUI.start('Cargando Convocatoria externa ...');

    this.activatedRoute.params.subscribe(params => {
      this.id = params['id']
      this.acc = params['acc']
      console.log("acc: " + this.acc);
      if (this.id != 0) {
      this.blockUI.start('Cargando Convocatoria ...');
      this._ss.verConvoExterna(this.id).subscribe(convocatoria => {
      this.convocatoria = convocatoria as Dconvexternas[]; 

      this.not_documentoHashCode = this.convocatoria.not_documentoHashCode;
      this.blockUI.stop(); 
    },
    error => {
      console.log(error);
    });
  
    }
    })

  }

  getDocFromService(url) {
    this.blockUI.start('Abriendo imagen...');
    this.printImage(url+this.id ).subscribe((response) => {
      const file = new Blob([response], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.blockUI.stop();
    },
      error => {
        this.blockUI.stop();
        console.log(error);
      });

  }

  printImage(url): any {
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<any>(url, httpOptions);
  }

}
