

<section class="speakers-full-width">
	<div class="container-fluid p-0">
		<div class="row">
			<div class="col-md-12 heading">
				<span class="title-icon float-left"><i class="fa fa-weixin"></i></span>
				<a [routerLink]="['/investigadores']">
					<h2 class="title">Investigadores <span class="title-desc">Conoce nuestro equipo</span></h2>
				</a>
			</div>
		</div><!-- Title row end -->
		<div class="row">
			<div class="col-12">
				<div class="slider-container" (mousedown)="startDrag($event)" (mouseup)="endDrag($event)" (mousemove)="onDrag($event)">
					<div class="slider" #slider>
					  <div *ngFor="let investigador of investigadores" class="slide">
						<div class="image-overlay">
						  <img src="https://www.sipu.ujed.mx/api/api/sipu/dinvestigadores/img/{{ investigador?.vinv_invid }}" alt="Imagen de ejemplo" class="fixed-image" />
						  <div class="overlay-text">
							<h5>{{ investigador?.vinv_nombre }}</h5>
							<p>{{ investigador?.vinv_titulo }}</p>
							<a [routerLink]="['/investigador', investigador?.vinv_invid]" class="btn btn-outline-white white">Ver</a>
							<ul class="list-inline">
								<li class="list-inline-item"><a href="https://themefisher.com/"><i
											class="fa fa-facebook"></i></a></li>
								<li class="list-inline-item"><a href="https://themefisher.com/"><i
											class="fa fa-twitter"></i></a></li>
								<li class="list-inline-item"><a href="https://themefisher.com/"><i
											class="fa fa-linkedin"></i></a></li>
							</ul>
						  </div>
						</div>
					  </div>
					</div>
					<button (click)="prev()" class="nav-button prev-button">Prev</button>
					<button (click)="next()" class="nav-button next-button">Sig</button>
				  </div>
			</div>
		</div>
	</div>
</section>





