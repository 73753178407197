<!--==================================
=                SIPU                =
===================================-->
<app-header></app-header>


<section class="page-title bg-title-noticias overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>Directorio</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
				  <li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
				  <li class="breadcrumb-item active">Directorio</li>
				</ol>
			</div>
		</div>
	</div>
</section>
	
	<div class="container">
		<div class="row">
			<div class="col-md-12 text-center">
				<h2 class="title2"> DIRECTORIO <span class="alternate">UJED</span>
					<span class="title-desc">- </span>
				</h2>
			</div>
		</div><!-- Title row end -->

		<div class="card text-center">
			<div class="card-header">
				<h3>Teléfonos Directos:</h3>
			</div>
			<div class="card-body">
			  <p class="card-text"><b>(8271200) <br> (8271277)</b></p>
			  <div class="team-social">
				<a class="fb" href="https://www.facebook.com/dipi.ujed/"><i class="fa fa-facebook"></i></a>
				<a class="twt" href="https://twitter.com/UJED_Oficial"><i class="fa fa-twitter"></i></a>
				<a class="youtube" href="https://www.youtube.com/channel/UCqQ5i9AnpTMv_uRvJmTsgEw"><i class="fa fa-youtube-play"></i></a>
			</div>
			</div>
		  </div>

		<div class="row">
			<div class="col-md-12 col-sm-6 mt-4 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team1.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3>Dr. Jaime Fernández Escárzaga</h3>
						<!-- <p></p> -->
						<p class="desc"> <b>Director Institucional de Posgrado e Investigación</b><br>
							Ext. 2130 <br>
							Correo electrónico: dipi.direccion@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
				
			</div>
			<!--/ Team 1 end -->
			<div class="col-md-12 col-sm-6 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team2.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3> LP. Brian Antonio Nava Almanza	</h3>
<!-- 						<p>Secretario de Dirección</p> -->
						<p class="desc"><b>Secretario de Dirección</b><br>
							Ext. 2131
							 <br>
							 Correo electrónico: dipi.sec.direccion@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="col-md-12 col-sm-6 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team3.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3> Lic. Erick Michell Campos Mendiola	</h3>
<!-- 						<p>Coordinación Institucional de Posgrado</p> -->
						<p class="desc"><b>Coordinación Institucional de Posgrado</b><br> 
							Ext. 2132
							 <br>
							 Correo electrónico: dipi.posgrado@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="col-md-12 col-sm-6 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team4.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3> L.A. Gabriela Enríquez Ontiveros	</h3>
<!-- 						<p>Coordinación de Difusión e Innovación</p> -->
						<p class="desc"><b>Coordinación de Difusión e Innovación</b><br> 
							Ext. 2138
							 <br>
							 Correo electrónico: gabriela.enriquez@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="col-md-12 col-sm-6 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team5.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3> Lic. Miriam Patricia Corral Carrillo	</h3>
						<p class="desc"><b>Auxiliar administrativa</b><br> 
							Ext. 2135
							 <br>
							 Correo electrónico: dipi.investigación@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="col-md-12 col-sm-6 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team6.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3> Lic. María Luisa Flores Herrera	</h3>
<!-- 						<p>Auxiliar administrativa</p> -->
						<p class="desc"><b>Auxiliar administrativa</b><br> 
							 <br>
							</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="col-md-12 col-sm-6 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team7.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3> C.P. Diana Judith Tovalín Vázquez	</h3>
				<!-- 		<p>Coordinación administrativa de proyectos extraordinarios</p> -->
						<p class="desc"><b>Coordinación administrativa de proyectos extraordinarios</b><br> 
							Ext. 2134
							 <br>
							 Correo electrónico: diana.tovalin@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>
			<div class="col-md-12 col-sm-6 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team8.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3> L.A. Esmeralda Rivera Flores	</h3>
						<p class="desc"><b>Coordinación contable de proyectos extraordinarios</b><br> 
							Ext. 2133
							 <br>
							 Correo electrónico: esmeralda.rivera@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="col-md-12 col-sm-6 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team9.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3>L.A. Nohemí del Carmen Alvarado Monroy	</h3>
						<!-- <p>Coordinación académica del PRODEP</p> -->
						<p class="desc"><b>Coordinación académica del PRODEP</b><br> 
							Ext. 2136
							 <br>
							 Correo electrónico: dipi.prodep@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="col-md-12 col-sm-6 mb-3">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team10.png" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3> L.A. Cinthia Anabel Avilés Salas	</h3>
<!-- 						<p>Coordinación general administrativa</p> -->
						<p class="desc"><b>Coordinación general administrativa</b><br>  
							Ext. 2137
							 <br>
							 Correo electrónico: dipifinanciero@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="col-md-12 col-sm-6 mb-4">
				<div class="team team-list-square wow slideInLeft">
					<div class="img-square">
						<img src="./assets/images/contactos/team11.jpeg" style="max-width: 70%;" alt="">
					</div>
					<div class="team-content">
						<h3> Dra. Manuela Vargas Hernández	</h3>
<!-- 						<p>Coordinación general de Seguimiento de Egresados</p> -->
						<p class="desc"><b>Coordinación general de Seguimiento de Egresados</b><br>  
							Ext. 2139
							 <br>
							 Correo electrónico: mimi.vargas@ujed.mx</p>

						<!-- <div class="team-social">
							<a class="fb" href="#"><i class="fa fa-facebook"></i></a>
							<a class="twt" href="#"><i class="fa fa-twitter"></i></a>
							<a class="gplus" href="#"><i class="fa fa-google-plus"></i></a>
						</div> -->
					</div>
				</div>
			</div>

			
			<!--/ Team 2 end -->
		</div>
		<!--/ Content row end -->

	</div>
	<!--/ container end -->
	
<app-footer></app-footer>
