import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpResponse } from '@angular/common/http';
import { environment, } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Dvonvocatoria } from '../models/dvconvocatoria';
import { Dnoticiasportal } from '../models/dnoticiasportal';

import {map, take} from 'rxjs/operators';

import { tap } from 'rxjs/operators';
import { Tcontactos } from '../ofertaeduca/programainfo/tcontactos';
import { Dconvexternas } from '../models/dconvexternas';
import { Dinvestigadores } from '../models/dinvestigadores';
import { Destadisticas } from '../models/destadisticas';


@Injectable({
  providedIn: 'root'
})
export class SipuService {

  public urlEndPoint = `${environment.rutaAPISipu}`;
 
  private cabeceras: HttpHeaders = new HttpHeaders ({'Content-Type' : 'application/json'})

  constructor( private http: HttpClient ) { }

  public listar(): Observable<Dvonvocatoria[]>{
    return this.http.get<Dvonvocatoria[]>(this.urlEndPoint+'/dvconvocatoria/convAS');
  }

  public listarDipi(): Observable<Dvonvocatoria[]>{
    return this.http.get<Dvonvocatoria[]>(this.urlEndPoint+'/dvconvocatoria/listardipi');
  }

  public listarPorUres(id: any){
    return this.http.get(`${this.urlEndPoint}/dvconvocatoria/${id}`);
  }


  public listarDatosContactoPorUres(id: any){
    return this.http.get(`${this.urlEndPoint}/durescontacto/${id}`);
  }

  public ver(id: number): Observable<Dvonvocatoria>{
    return this.http.get<Dvonvocatoria>(`${this.urlEndPoint}/dvconvocatoria/${id}`);
  }

  public verConvocatoria(id: number){
    return this.http.get(`${this.urlEndPoint}/dvconvocatoria/verXID/${id}`);
  }

  public listarNoticiasPorFecha(): Observable<Dnoticiasportal[]>{
    return this.http.get<Dnoticiasportal[]>(this.urlEndPoint+'/dnoticiasportal/porFecha');
  }

  public verActivas(): Observable<Dnoticiasportal[]>{
    return this.http.get<Dnoticiasportal[]>(this.urlEndPoint+'/dnoticiasportal/verActivas');
  }

  public verActivasEgresados(): Observable<Dnoticiasportal[]>{
    return this.http.get<Dnoticiasportal[]>(this.urlEndPoint+'/dnoticiasportal/verActivasEgresados');
  }

  public verNoticia(id: number): Observable<Dnoticiasportal>{
    return this.http.get<Dnoticiasportal>(`${this.urlEndPoint}/dnoticiasportal/${id}`);
  }

  public getContacto(id: number): Observable<Tcontactos[]>{
    return this.http.get<Tcontactos[]>(`${this.urlEndPoint}/durescontacto/${id}`);
  }

//------------------------CONVOCATORIAS EXTERNAS

public listarConvoExternas(): Observable<Dconvexternas[]>{
  return this.http.get<Dconvexternas[]>(this.urlEndPoint+'/dconvexternas/');
}

public verConvoExterna(id: number){
  return this.http.get(`${this.urlEndPoint}/dconvexternas/${id}`);
}


//------------------------INVESTIGADORES

public listarInvestigador(): Observable<Dinvestigadores[]>{
  return this.http.get<Dinvestigadores[]>(this.urlEndPoint+'/dinvestigadores/getListado');
}

public verInvestigador(id: number){
  return this.http.get(`${this.urlEndPoint}/dinvestigadores/${id}`);
}





public verInfoInvestigador(id: any) {
  return this.http.get(`${this.urlEndPoint}/dinvestigadores/getInfo/${id}`);
}

public verGradoInvestigador(id: any) {
  return this.http.get(`${this.urlEndPoint}/dinvestigadores/getGrado/${id}`);
}

public verLibrosInvestigador(id: any) {
  return this.http.get(`${this.urlEndPoint}/dinvestigadores/getLibros/${id}`);
}

public verArticulosInvestigador(id: any) {
  return this.http.get(`${this.urlEndPoint}/dinvestigadores/getArticulos/${id}`);
}

public verContratosInvestigador(id: any) {
  return this.http.get(`${this.urlEndPoint}/dinvestigadores/paquete/${id}`);
}

public uploadImgInvestigador (id: number, imagen: File): Observable<Dinvestigadores>{
  const formData = new FormData();
  formData.append('imagen', imagen);
  return this.http.put<Dinvestigadores>(`${this.urlEndPoint}/dinvestigadores/uploadImg/${id}`, formData)
}
public verCuerposAcadInvestigador(id: any) {
  return this.http.get(`${this.urlEndPoint}/dinvestigadores/CuerposAcad/${id}`);
}


public listarEstadisticasDipi(): Observable<Destadisticas[]>{
  return this.http.get<Destadisticas[]>(this.urlEndPoint+'/DEstadisticasPortal/');
}



}
