import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SipuService } from '../services/sipu.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-convoexternas',
  templateUrl: './convoexternas.component.html',
  styleUrls: ['./convoexternas.component.css']
})
export class ConvoexternasComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  convoExternas: any;

  constructor(private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.blockUI.start('Cargando Convocatorias externas ...');
    this._ss.listarConvoExternas().subscribe(convoExternas => {
      this.convoExternas = convoExternas;  
       this.blockUI.stop(); 
    },
    error => {
      console.log(error);
    });
  


  }

}
