import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SipuService } from 'src/app/services/sipu.service';
import { environment } from 'src/environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Tcontactos } from './tcontactos';
import { Dvonvocatoria } from 'src/app/models/dvconvocatoria';

@Component({
  selector: 'app-programainfo',
  templateUrl: './programainfo.component.html',
  styleUrls: ['./programainfo.component.css']
})
export class ProgramainfoComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  urlEndPoint = `${environment.rutaAPISipu}`;

  id: number;
  acc: string;
  convocatoria: any;
  contacto: Tcontactos[];

  private subscription: Subscription;
  ures: any;

  constructor(private activatedRoute: ActivatedRoute, private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {

    window.scroll(0, 0);
    //this.getProgramas();
    this.blockUI.start('Cargando Programa ...');

    this.activatedRoute.params.subscribe(params => {
      this.id = params['id']
      this.acc = params['acc']
      //console.log("id: "+this.id);
      console.log("acc: " + this.acc);
      if (this.id != 0) {

        
        this._ss.verConvocatoria(this.id).subscribe(
          (convocatoria) => {
            this.convocatoria = convocatoria as Dvonvocatoria[];
            console.log(this.convocatoria);

            this.ures=this.convocatoria.vcon_ures;

            console.log('Ures',this.ures);

            this.subscription = this._ss.getContacto(this.ures).subscribe((contacto) => {
              this.contacto = contacto as Tcontactos[];
        
              console.log('*******************');
              console.log('contacto',this.contacto);
        
              this.blockUI.stop();
            });
          }
        )}
    })
  }
}
