import { Component, OnInit } from '@angular/core';
import { SipuService } from 'src/app/services/sipu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-exaujedm',
  templateUrl: './exaujedm.component.html',
  styleUrls: ['./exaujedm.component.css']
})
export class ExaujedmComponent implements OnInit {

  noticias: any;
  urlEndPoint = `${environment.rutaAPISipu}`;
  hyaNoticiasActivas:boolean=false;
  numNoticas:number=0;

  constructor(private _ss: SipuService) { }

  ngOnInit(): void {

    this._ss.verActivasEgresados().subscribe(noticias => {
      this.noticias = noticias;
      

      console.log('egresadosNoti',this.noticias.length);
      this.numNoticas=this.noticias.length;

      if (this.noticias.length > 0) {
        this.hyaNoticiasActivas=true;
      }

       
    },
    error => {
      console.log(error);
    });
  }

}
