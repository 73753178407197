<app-header></app-header>
<block-ui>

<section class="page-title bg-title-noticias overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>NOTICIAS</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
				  <li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
				  <li class="breadcrumb-item active">NOTICIAS</li>
				</ol>
			</div>
		</div>
	</div>
</section>

<div class="row">
	<div class="col-md-12 text-center mt-1">
		<h2 class="title2"> NOTICIAS <!-- <span class="alternate">UJED</span> -->
			<span class="title-desc"> ÚLTIMAS NOTICIAS POSGRADO UJED </span>
		</h2>
	</div>
</div><!-- Title row end -->

<section class="news section">
	<div class="container">
		<div class="row justify-content-center mt-30" >
			<div class="col-lg-4 col-md-6 col-sm-8" *ngFor="let item of noticias | paginate: { itemsPerPage: 9, currentPage: currentPage }">
				<a [routerLink]="['/noticiainfo',item.dnot_notid]">
				<div class="blog-post">
					<div class="post-thumb" >
						<a  [routerLink]="['/noticiainfo',item.dnot_notid]">
							<img [src]="urlEndPoint + '/dnoticiasportal/img/' + item.dnot_notid + '?not_imagenHashCode=' + item.not_imagenHashCode"
							 alt="post-image" width="100%" height="180px">
						</a>
					</div>
					<div class="post-content">
						<div class="date">
							<h4><span>{{item.dnot_fec}}</span></h4>
						</div>
						<div class="post-title">
							<h2><a [routerLink]="['/noticiainfo',item.dnot_notid]">{{item.dnot_titulo}}</a></h2>
						</div>
						<div class="post-meta">
							<ul class="list-inline">
								<li class="list-inline-item">
									<i class="fa fa-user-o"></i>
									<a href="#">{{item.dnot_autor}}</a>
								</li>
								<li class="list-inline-item">
									<i class="fa fa-hashtag"></i>
									<a href="#">Noticias</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</a>
			</div>

			<div class="col-12 text-center">
				<nav class="d-flex justify-content-center">
				  <ul class="pagination">
					<li class="page-item" [class.disabled]="currentPage === 1">
						<a class="page-link" (click)="goToPreviousPage()" aria-label="Previous">
						  <span aria-hidden="true">&laquo;</span>
						  <span class="sr-only">Anterior</span>
						</a>
					  </li>
					<li class="page-item" *ngFor="let page of totalPages()" [class.active]="currentPage === page">
					  <a class="page-link"  (click)="currentPage = page">{{ page }}</a>
					</li>
					<li class="page-item" [class.disabled]="!totalPages().includes(currentPage + 1)">
						<a class="page-link" (click)="goToNextPage()" aria-label="Next">
						  <span aria-hidden="true">&raquo;</span>
						  <span class="sr-only">Siguiente</span>
						</a>
					  </li>
				  </ul>
				</nav>
			  </div>


			

			
		</div>
	</div>
</section>
</block-ui>


<app-footer></app-footer>
