import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SipuService } from '../../services/sipu.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-listaconvocatorias',
  templateUrl: './listaconvocatorias.component.html',
  styleUrls: ['./listaconvocatorias.component.css']
})
export class ListaconvocatoriasComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  convocatorias: any;
  conv: any[];
  nomUres: any;

  constructor(private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.blockUI.start('Cargando Convocatorias ...');
    this._ss.listar().subscribe(convocatorias => {
      this.convocatorias = convocatorias;
      this.conv = this.convocatorias;
      this.nomUres = this.removeDuplicates(this.convocatorias,'vcon_descures');
      console.log('convocatorias', this.convocatorias);
      this.blockUI.stop();
    },
      error => {
        console.log(error);
      });


  }

  

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  applyFilter(event: Event) {

    this.blockUI.start('Cargando Convocatorias ...');
    console.log('***');
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('valor ' + filterValue);

    if (filterValue != '') {
      this.convocatorias = this.conv.filter(
        (conv) => conv.vcon_descures == filterValue
      );

      console.log(this.conv);
    }
    else {
      this.convocatorias = this.conv;
    }

    this.blockUI.stop();

  }

}
