import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SipuService } from 'src/app/services/sipu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html'
})
export class SlideComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  noticias: any;
  urlEndPoint = `${environment.rutaAPISipu}`;
  hyaNoticiasActivas:boolean=false;
  numNoticas:number=0;

  constructor(private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {

    this.blockUI.start('Cargando noticias ...');
    this._ss.verActivas().subscribe(noticias => {
      this.noticias = noticias; 

      console.log('this.noticas',this.noticias.length);
      this.numNoticas=this.noticias.length;

      if (this.noticias.length > 0) {
        this.hyaNoticiasActivas=true;
      }

       this.blockUI.stop(); 
    },
    error => {
      console.log(error);
    });

  }

}
