<!--==================================
=            Registration            =
===================================-->

<section class="registration">
	<div class="container-fuild p-0">
		<div class="row">
			<div class="col-lg-6 col-md-12 p-0">
				<div class="service-block bg-service overlay-primary text-center">
					<div class="row no-gutters">
						<div class="col-6">
							<!-- Service item -->
							<div class="service-item">
								<i class="fa fa-microphone"></i>
								<h5>CONVOCATORIAS</h5>
							</div>
						</div>
						<div class="col-6">
							<!-- Service item -->
							<div class="service-item">
								<i class="fa fa-flag"></i>
								<h5>PASOS A SEGUIR</h5>
							</div>
						</div>
						<div class="col-6">
							<!-- Service item -->
							<div class="service-item">
								<i class="fa fa-ticket"></i>
								<h5>MANUALES</h5>
							</div>
						</div>
						<div class="col-6">
							<!-- Service item -->
							<div class="service-item">
								<i class="fa fa-step-forward"></i>
								<h5>TUTORIALES</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-12 p-0">
				<div class="registration-block bg-registration overlay-dark">
					<div class="block">
						<div class="title text-left">
							<h3>CONOCE MÁS SOBRE  <span class="alternate">SIPU</span></h3>
							<p>
								Inscríbete a través del Sistema de Información del Posgrado Universitario
<!-- 								Te invitamos a inscribirte en nuestros programas de posgrado a través del 
								<b>Sistema de Información del Posgrado Universitario</b>. 
								Únete a nosotros y forma parte de nuestra comunidad académica. 
								Puedes ver los programas en diferentes areas
								 para que puedas continuar aprendiendo. El proceso de 
								inscripción es fácil y seguro, solo necesitas completar unos sencillos pasos en el sistema -->
								

                            </p>
							<p>  ¡Únete a nosotros!</p>
						</div>

						<div class="col-12">
							<p class="animated4">
								<a [routerLink]="['/sipu']" class="mr-3 slider btn btn btn-outline-white white">Conoce Más</a>
								<a href="https://www.sipu.ujed.mx/" class="slider btn btn btn-outline-white white">Ir a Sipu</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!--====  End of Registration  ====-->