<!--==================================
=                SIPU                =
===================================-->
<app-header></app-header>

<section class="page-title bg-title-sipu">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>SISTEMA DE INFORMACION DE POSGRADO UNIVERSITARIO</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
					<li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
					<li class="breadcrumb-item active">SIPU</li>
				</ol>
			</div>
		</div>
	</div>
</section>

<section id="image-block" class="image-block p-5px">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-6 ts-padding"
				style="height:650px;background:url(./assets/images/SIPU.png) 50% 50% / contain no-repeat;">

			</div>
			<div class="col-md-6 ts-padding img-block-right">
				<div class="img-block-head text-left">
					<h3>Sistema de Información del Posgrado Universitario</h3>
					<br><br>
					<p>La Universidad Juárez del Estado de Durango comprometida con su misión de transformar de forma
						positiva el entorno social mediante la formación integral de los ciudadanos,
						invita a los interesados a conocer su oferta educativa de nivel de posgrado promoviendo la
						investigación científica y la innovación y aplicación del conocimiento.
						<br><br>
						Nuestra Máxima Casa de Estudios en su afán de mantenerse a la vanguardia pone a tu disposición
						el SIPU (Sistema de Información del Posgrado Universitario)
						como herramienta que facilitará tu tránsito como estudiante, cuidando siempre la optimización de
						los recursos que tenemos al alcance.
					</p>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- About tab start -->
<section id="about">
	<ul class="nav facts-wrapper wow fadeInLeft text-center">
		<li class="facts one col-md-6 col-sm-6"><a class="nav-link active" href="#tab1" data-toggle="tab">
				<h3>ADMISION</h3>
			</a></li>
		<li class="facts two col-md-6 col-sm-6"><a class="nav-link" href="#tab2" data-toggle="tab">
				<h3>INSCRIPCION</h3>
			</a></li>
	</ul>
	<div class="container">

		<div class="widget widget-tab">

			<div class="tab-content">
				<div class="tab-pane active" id="tab1">
					<div class="mb-5">
						<h3> <b>Inicia tu proceso de admisión siguiendo los siguientes pasos </b></h3>
					</div>

					<div class="row landing-tab">
						<div class="col-md-4">
							<div class="nav flex-column nav-pills border-md-right h-100 mr-lg-5 mb-3 mb-md-0"
								id="v-pills-tab" role="tablist" aria-orientation="vertical">
								<a class="animated fadeIn nav-link  py-2 py-lg-4  active d-flex align-items-center"
									data-toggle="pill" href="#tab_1" role="tab" aria-selected="true">
									<i class="fa fa-search mr-4"></i>

									<span class="h4 mb-0 font-weight-bold">1. Consulta </span>
								</a>
								<a class="animated fadeIn nav-link py-2 py-lg-4 d-flex align-items-center"
									data-toggle="pill" href="#tab_2" role="tab" aria-selected="true">

									<i class="fa fa-solid fa-envelope-o mr-4"></i>
									<span class="h4 mb-0 font-weight-bold">2. Registro</span>
								</a>
								<a class="animated fadeIn nav-link py-2 py-lg-4 d-flex align-items-center"
									data-toggle="pill" href="#tab_3" role="tab" aria-selected="true">
									<i class="fa fa-pencil mr-4"></i>
									<span class="h4 mb-0 font-weight-bold">3. Programa Académico</span>
								</a>

								<a class="animated fadeIn nav-link py-2 py-lg-4 d-flex align-items-center"
									data-toggle="pill" href="#tab_4" role="tab" aria-selected="true">
									<i class="fa fa-file mr-4"></i>
									<span class="h4 mb-0 font-weight-bold">4. Expediente Digital</span>
								</a>

								<a class="animated fadeIn nav-link py-2 py-lg-4 d-flex align-items-center"
									data-toggle="pill" href="#tab_5" role="tab" aria-selected="true">
									<i class="fa fa-list-ol mr-4"></i>
									<span class="h4 mb-0 font-weight-bold">5. Indicaciones De La Convocatoria</span>
								</a>
							</div>
						</div>
						<div class="col-md-8">
							<div class="tab-content" id="v-pills-tabContent">
								<div class="tab-pane pl-md-5 fade show active animated px-3  fadeInLeft" id="tab_1"
									role="tabpanel">
									<!-- 	<i class="fa fa-trophy icon-xl text-primary mb-4"></i> -->
									<h3>Consulta La Convocatoria Del Programa Académico De Tu Interés</h3>
									<p>Consulta los requisitos para que curses con nosotros el programa académico de tu
										interés
										https://www.sipu.ujed.mx/</p>

									<img src="./assets/images/sipu/1.png" alt="post-image" class="img-fluid">
								</div>
								<div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_2" role="tabpanel">

									<h3>Realiza Tu Registro</h3>
									<p>Ingresa a la pestaña de regístrate y llena tus datos, te llegará un correo
										electrónico con tu
										usuario y contraseña </p>

									<img src="./assets/images/sipu/2.png" alt="post-image" class="img-fluid">
								</div>
								<div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_3" role="tabpanel">

									<h3>Regístrate En El Programa Académico De Tu Interés</h3>
									<p>Con tu usuario y contraseña ingresa al apartado de ASPIRANTES, da clic en la
										pestaña DATOS DE ASPIRANTE, después registro a convocatorias y en nueva
										convocatoria
										comienza tu registro</p>

									<img src="./assets/images/sipu/3.png" alt="post-image" class="img-fluid">
								</div>
								<div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_4" role="tabpanel">

									<h3>Crea Tu Expediente Digital</h3>
									<p>Sube la documentación requerida para terminar tu proceso de inscripción de
										aspirante
										¡este paso es muy importante, sin él no podrás continuar en el proceso!</p>

									<img src="./assets/images/sipu/4.png" alt="post-image" class="img-fluid">
								</div>
								<div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_5" role="tabpanel">

									<h3>Sigue Las Indicaciones De La Convocatoria</h3>
									<p>Es muy importante que estés atento a las indicaciones establecidas en la
										convocatoria para
										que puedas concluir satisfactoriamente tu proceso</p>

									<img src="./assets/images/sipu/5.png" alt="post-image" class="img-fluid">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="tab-pane" id="tab2">
					<div class="mb-5">
						<h3> <b>Inicia tu proceso de inscripción siguiendo los siguientes pasos </b></h3>
					</div>

					<div class="row landing-tab">
						<div class="col-md-4">
							<div class="nav flex-column nav-pills border-md-right h-100 mr-lg-5 mb-3 mb-md-0"
								id="v-pills-tab" role="tablist" aria-orientation="vertical">
								<a class="animated fadeIn nav-link  py-2 py-lg-4  active d-flex align-items-center"
									data-toggle="pill" href="#tab_6" role="tab" aria-selected="true">
									<i class="fa fa-search mr-4"></i>

									<span class="h4 mb-0 font-weight-bold">1. Ingreso </span>
								</a>
								<a class="animated fadeIn nav-link py-2 py-lg-4 d-flex align-items-center"
									data-toggle="pill" href="#tab_7" role="tab" aria-selected="true">

									<i class="fa fa-solid fa-envelope-o mr-4"></i>
									<span class="h4 mb-0 font-weight-bold">2. Ficha de Inscripción </span>
								</a>
								<a class="animated fadeIn nav-link py-2 py-lg-4 d-flex align-items-center"
									data-toggle="pill" href="#tab_8" role="tab" aria-selected="true">
									<i class="fa fa-pencil mr-4"></i>
									<span class="h4 mb-0 font-weight-bold">3. Sube tu pago </span>
								</a>

								<a class="animated fadeIn nav-link py-2 py-lg-4 d-flex align-items-center"
									data-toggle="pill" href="#tab_9" role="tab" aria-selected="true">
									<i class="fa fa-file mr-4"></i>
									<span class="h4 mb-0 font-weight-bold">4. Consulta tus Calificaciones</span>
								</a>

							</div>
						</div>
						<div class="col-md-8">
							<div class="tab-content" id="v-pills-tabContent">
								<div class="tab-pane pl-md-5 fade show active animated px-3  fadeInLeft" id="tab_6"
									role="tabpanel">
									<!-- 	<i class="fa fa-trophy icon-xl text-primary mb-4"></i> -->
									<h3>Ingresa al Portal de Alumnos con tu usuario y contraseña.</h3>
									<!-- <p>Consulta los requisitos para que curses con nosotros el programa académico de tu
										interés
										https://www.sipu.ujed.mx/</p> -->

									<img src="./assets/images/sipu/Ingreso.png" alt="post-image" class="img-fluid">
								</div>
								<div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_7" role="tabpanel">

									<h3>Verifica tu Ficha de Inscripción</h3>
									<!-- <p>Ingresa a la pestaña de regístrate y llena tus datos, te llegará un correo
										electrónico con tu
										usuario y contraseña </p> -->

									<img src="./assets/images/sipu/Ficha_de_inscripción.png" alt="post-image"
										class="img-fluid">
								</div>
								<div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_8" role="tabpanel">

									<h3>Sube tu pago</h3>
									<!-- <p>Con tu usuario y contraseña ingresa al apartado de ASPIRANTES, da clic en la
										pestaña DATOS DE ASPIRANTE, después registro a convocatorias y en nueva
										convocatoria
										comienza tu registro</p> -->

									<img src="./assets/images/sipu/Sube_tu_pago.png" alt="post-image" class="img-fluid">
								</div>
								<div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_9" role="tabpanel">

									<h3>Revisa tus Calificaciones</h3>
									<!-- <p>Sube la documentación requerida para terminar tu proceso de inscripción de
										aspirante
										¡este paso es muy importante, sin él no podrás continuar en el proceso!</p> -->

									<img src="./assets/images/sipu/Consulta_tus_calificaciones.png" alt="post-image"
										class="img-fluid">
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div><!-- End default tabs -->


	</div><!-- Container end -->
</section><!-- About end -->



<div id="image-block" class="image-block p-5px mb-5">
<div class="container">
<div class="row landing-tab">
	<div class="col-md-5 ts-padding" >
		<div class="img-block-head text-center">
			<h3>Tutoriales e Información</h3>
			<p>En nuestra sección de Tutoriales e Información, una fuente dedicada a
				ilustrar a aspirantes, alumnos y personal administrativo sobre el uso y navegación del
				Sistema de Información del Posgrado Universitario (SIPU). Aquí encontrarás videos didácticos
				que te servirán como guías, asegurándote de que aproveches al máximo las funcionalidades que ofrece SIPU.
			</p>
		</div>
		<div class="nav flex-column nav-pills border-md-right h-100 mr-lg-5 mb-3 mb-md-0"
			id="v-pills-tab" role="tablist" aria-orientation="vertical">
			<a class="animated fadeIn nav-link  py-2 py-lg-4  active d-flex align-items-center"
				data-toggle="pill" href="#tab_20" role="tab" aria-selected="true">
				<i class="fa fa-angle-right mr-4"></i>
				<span class="h4 mb-0 font-weight-bold">ASPIRANTES / ALUMNOS </span>
			</a>
			<a class="animated fadeIn nav-link py-2 py-lg-4 d-flex align-items-center"
				data-toggle="pill" href="#tab_21" role="tab" aria-selected="true">
				<i class="fa fa-angle-right mr-4"></i>
				<span class="h4 mb-0 font-weight-bold"> MAESTROS</span>
			</a>
		</div>
	</div>
	<div class="col-md-7 mt-3">
		<div class="tab-content" id="v-pills-tabContent">
			<div class="tab-pane pl-md-5 fade show active animated px-3  fadeInLeft" id="tab_20" role="tabpanel">
				<h3 class="mb-3">Aspirantes/Alumnos</h3>
				
				<div class="video-container">
					<iframe width="560" height="315" src="https://www.youtube.com/embed/MxRWkIqNmnI" 
						title="YouTube video player" frameborder="0" 
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
						allowfullscreen>
					</iframe>
				</div>
			</div>
			<div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_21" role="tabpanel">
				<h3 class="mb-3">Academicos</h3>

				<div class="video-container">
					<iframe width="560" height="315"
						src="https://www.youtube.com/embed/videoseries?list=PLFJpSegRK_PK7nSyyv_YYKzBRLGfJybwh"
						title="YouTube video player" frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen>
					</iframe>
				</div>
			</div>
			
		</div>
	</div>
</div>
</div>
</div>



<app-footer></app-footer>