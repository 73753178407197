
<app-header></app-header>

<app-slide></app-slide>
<app-service></app-service>
<app-ofertaeducativam></app-ofertaeducativam>
<app-sipum></app-sipum>
<app-investigadoresm></app-investigadoresm> 
<app-exaujedm></app-exaujedm>

<app-footer></app-footer>




