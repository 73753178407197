<block-ui>

  <app-header></app-header>

  <section class="page-title bg-title-invest overlay-fondo">
    <div class="container">
      <div class="row">
        <div class="col-12 text-left">
          <div class="title">
            <h2>Investigadores</h2>
          </div>
          <ol class="breadcrumb justify-content-left p-0 m-0">
            <li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
            <li class="breadcrumb-item active">Investigadores</li>
          </ol>
        </div>
      </div>
    </div>
  </section>


  <!--==============================
=            Speakers            =
===============================-->
  <div class="row">
    <div class="col-md-12 text-center">
      <h2 class="title2"> INVESTIGADORES <span class="alternate">UJED</span>
        <span class="title-desc">
          ¡Bienvenido a la página de Investigadores de la UJED!
         </span>
      </h2>
    </div>
  </div><!-- Title row end -->


  <section class="section schedule">
    <div class="container">

      <div class="row">
        <div class="col-12">
          <div class="schedule-tab">
            <ul class="nav nav-pills text-center">
              <li class="nav-item">
                <a class="nav-link active" href="#candidato" data-toggle="pill">
                  Candidato
                  <span>-</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#sni1" data-toggle="pill">
                  SNI I
                  <span>-</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#sni2" data-toggle="pill">
                  SNI II
                  <span>-</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#sni3" data-toggle="pill">
                  SNI III
                  <span>-</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="schedule-contents bg-schedule">
            <div class="tab-content" id="pills-tabContent">

              <div class="tab-pane fade show active schedule-item" id="candidato">

                <div class="container">
                  <div class="col-12" style="vertical-align: middle">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th style="width: 180px;">Nivel SNI</th>  
                          <th> </th>
                          <th>Investigador</th>
                          <th>Area de Investigacion </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="candidato && candidato.length > 0; else noData">
                        <tr *ngFor="let c of candidato" class="block" [routerLink]="['/investigador', c.vinv_invid]">
                          <td style="vertical-align: middle"> <i class="fa fa-user-circle mr-2"></i> {{c.vinv_nivel_descrip}}</td>
                          <td class="text-left">
                            <img *ngIf="c.not_imagenHashCode" 
                              src="https://www.sipu.ujed.mx/api/api/sipu/dinvestigadores/img/{{c.vinv_invid}}" class="img-size"/>
                            <img *ngIf="!c.not_imagenHashCode" src="./assets/images/sinimagen.jpg">
                          </td>
                          <td style="vertical-align: middle">
                            <span class="name ml-2"> {{c.vinv_nombre}}</span>
                          </td>
                          <td style="vertical-align: middle"> {{c.vinv_area}}</td>
                        </tr>
                      </ng-container>
                      <ng-template #noData>
                        <tr>
                          <td colspan="4" class="text-center">No hay Investigadores para mostrar.</td>
                        </tr>
                      </ng-template>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>




              <div class="tab-pane fade show schedule-item" id="sni1">

                <div class="container">
                  <div class="col-12" style="vertical-align: middle">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th style="width: 180px;">Nivel SNI</th>
                          <th> </th>
                          <th>Investigador</th>
                          <th>Area de Investigacion </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="sni1 && sni1.length > 0; else noData">
                        <tr *ngFor="let s1 of sni1" class="block" [routerLink]="['/investigador', s1.vinv_invid]">
                          <td style="vertical-align: middle"> <i class="fa fa-user-circle mr-2"></i> {{s1.vinv_nivel_descrip}}</td>
                          <td class="text-left">
                            <img *ngIf="s1.not_imagenHashCode"
                              src="https://www.sipu.ujed.mx/api/api/sipu/dinvestigadores/img/{{s1.vinv_invid}}" class="img-size" />
                            <img *ngIf="!s1.not_imagenHashCode" src="./assets/images/sinimagen.jpg">
                          </td>
                          <td style="vertical-align: middle">
                            <span class="name ml-2"> {{s1.vinv_nombre}}</span>
                          </td>
                          <td style="vertical-align: middle"> {{s1.vinv_area}}</td>
                        </tr>
                      </ng-container>
                      <ng-template #noData>
                        <tr>
                          <td colspan="4" class="text-center">No hay Investigadores para mostrar.</td>
                        </tr>
                      </ng-template>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>



              <div class="tab-pane fade schedule-item" id="sni2">

                <div class="container">
                  <div class="col-12" style="vertical-align: middle">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th style="width: 180px;">Nivel SNI</th>
                          <th> </th>
                          <th>Investigador</th>
                          <th>Area de Investigacion </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="sni2 && sni2.length > 0; else noData">
                        <tr *ngFor="let s2 of sni2" class="block" [routerLink]="['/investigador', s2.vinv_invid]">
                          <td style="vertical-align: middle" > <i class="fa fa-user-circle mr-2"></i> {{s2.vinv_nivel_descrip}}</td>
                          <td class="text-left">
                            <img *ngIf="s2.not_imagenHashCode"
                              src="https://www.sipu.ujed.mx/api/api/sipu/dinvestigadores/img/{{s2.vinv_invid}}" class="img-size"/>
                            <img *ngIf="!s2.not_imagenHashCode" src="./assets/images/sinimagen.jpg">
                             </td>
                             <td style="vertical-align: middle">
                              <span class="name ml-2"> {{s2.vinv_nombre}}</span>
                            </td>
                         
                          <td style="vertical-align: middle"> {{s2.vinv_area}}</td>
                        </tr>
                      </ng-container>
                      <ng-template #noData>
                        <tr>
                          <td colspan="4" class="text-center">No hay Investigadores para mostrar.</td>
                        </tr>
                      </ng-template>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

              <div class="tab-pane fade schedule-item" id="sni3">

                <div class="container">
                  <div class="col-12" style="vertical-align: middle">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th style="width: 180px;">Nivel SNI</th>
                          <th> </th>
                          <th>Investigador</th>
                          <th>Area de Investigacion </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="sni3 && sni3.length > 0; else noData">
                        <tr *ngFor="let s3 of sni3" class="block" [routerLink]="['/investigador', s3.vinv_invid]">
                          <td style="vertical-align: middle"> <i class="fa fa-user-circle mr-2"></i> {{s3.vinv_nivel_descrip}}</td>
                          <td class="text-left">
                            <img *ngIf="s3.not_imagenHashCode"
                              src="https://www.sipu.ujed.mx/api/api/sipu/dinvestigadores/img/{{s3.vinv_invid}}"  class="img-size" />
                            <img *ngIf="!s3.not_imagenHashCode" src="./assets/images/sinimagen.jpg">
                          </td>
                          <td style="vertical-align: middle"> 
                              <span class="name ml-2"> {{s3.vinv_nombre}}</span>
                          </td>

                          <td style="vertical-align: middle"> {{s3.vinv_area}}</td>
                        </tr>
                      </ng-container>
                      <ng-template #noData>
                        <tr>
                          <td colspan="4" class="text-center">No hay Investigadores para mostrar.</td>
                        </tr>
                      </ng-template>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </section>


  <!-- 

<div class="container">
	<div class="col-12" style="vertical-align: middle">
	<table class="table table-hover">
	  <thead>
		<tr>
		  <th style="width: 180px;">Nivel SNI</th>
		  <th >Investigador</th>
		  <th>Area de Investigacion </th>
		</tr>
	  </thead>
	  <tbody >
		<tr *ngFor="let i of investigadores" class="block" [routerLink]="['/investigador', i.vinv_invid]">
		  <td> <i class="fa fa-user-circle mr-2"></i> {{i.vinv_nivel_descrip}}</td>
		  <td class="text-left"> 
			<img src="./assets/images/speaker-thumb-two.jpg">
				<span class="name ml-2"> {{i.vinv_nombre}}</span> </td>
		  <td style="vertical-align: middle"> {{i.vinv_area}}</td>
		</tr>
	  </tbody>
	</table>
	</div>
  </div>
 -->

  <!--====  End of Speakers  ====-->
  <app-footer></app-footer>
</block-ui>