<block-ui>
<app-header></app-header>
<section class="page-title bg-title-convex overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>CONVOCATORIAS EXTERNAS</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
				  <li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
				  <li class="breadcrumb-item active">Convocatorias Externas</li>
				</ol>
			</div>
		</div>
	</div>
</section>

<!-- Portfolio start -->
<div class="col-12">
<section id="portfolio" class="portfolio mb-20px" >
	<div class="container">
		<div class="row">
			<div class="col-lg-6 heading text-lg-left text-center">
				<span class="title-icon classic"><i class="fa fa-university"></i></span>
				<h2 class="title">Convocatorias Externas <span class="title-desc">Conoce más convocatorias</span></h2>
			</div>
		</div>
	</div>

	<div class="container-fluid">
		<div class="row isotope" id="isotope" >
			<div class="col-sm-6 col-md-4 col-lg-3 web-design isotope-item" *ngFor="let item of convoExternas">

				<a [routerLink]="['/convoexternoinfo',item.dcex_convid]">
				<div class="grid">
					<ng-container *ngIf="item.not_imagenHashCode != null; else internalBlock">

						<figure class="m-0 effect-oscar overlay-fondo">
							<img class="centered-and-cropped" width="200" height="400" src="https://www.sipu.ujed.mx/api/api/sipu/dconvexternas/img/{{ item.dcex_convid }}" alt="" >
							<figcaption>
								<h3>{{item.dcex_titulo}}</h3>
								<a class="link icon-pentagon" [routerLink]="['/convoexternoinfo', item.dcex_convid]"><i class="fa fa-link"></i></a>
								<!-- <a class="view icon-pentagon" data-rel="prettyPhoto"
								href="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ item.vcon_convid }}"><i class="fa fa-search"></i></a> -->
							</figcaption>
						</figure>

					</ng-container>

					<ng-template #internalBlock>
						<figure class="m-0 effect-oscar">
							<img class="centered-and-cropped" width="200" height="400" src="./assets/images/noimagenn.png" alt="" >
							<figcaption>
								<h3>{{item.dcex_titulo}}</h3>
								<a class="link icon-pentagon" [routerLink]="['/convoexternoinfo', item.dcex_convid]"><i class="fa fa-link"></i></a>
								<!-- <a class="view icon-pentagon" data-rel="prettyPhoto"
								href="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ item.vcon_convid }}"><i class="fa fa-search"></i></a> -->
							</figcaption>
						</figure>


					</ng-template>
				</div>
			</a>
			</div><!-- Isotope item end -->
		</div>
		<!-- Content row end -->
	</div><!-- Container end -->
</section><!-- Portfolio end -->
</div>

<div class="hr5" style="margin-top:30px; margin-bottom:25px;"></div>

<div class="col-12">
<!-- Parallax 1 start -->
<section class="parallax parallax5 mt-20px" >
	<div class="parallax-overlay"></div>
	<div class="container">
		<div class="row">
			<div class="col-md-12 text-center">
				<h2>Te interesa alguna de las convocatorias externas?</h2>
				<h3>Conoce más info mandando correo a:</h3>
				<h4 class="lowercase">dipi.servicios@ujed.mx</h4>
				<p>
					<!-- <a href="#" class="btn btn-outline-white mr-2">Conoce más</a>
					<a href="#" class="btn btn-outline-primary text-white">	Sipu</a> -->
				</p>
			</div>
		</div>
	</div><!-- Container end -->
</section><!-- Parallax 1 end -->
</div>


<!-- Clients start -->
<section id="clients" class="clients">
	<div class="container">
		<div class="row wow fadeInLeft">
			<div id="client-carousel" class="col-sm-12 owl-carousel owl-theme text-center client-carousel">
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/clients/client1.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/clients/client2.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/clients/client3.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/clients/client4.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/clients/client5.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/clients/client6.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/clients/client7.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/clients/client8.png" alt="client">
					</a>
				</figure>
			</div><!-- Owl carousel end -->
		</div><!-- Main row end -->
	</div>
	<!--/ Container end -->
</section>
<!--/ Clients end -->

<app-footer></app-footer>
</block-ui>