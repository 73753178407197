import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Login } from './login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  public isLoading:boolean=false;

  public log: Login = new Login();

  constructor() { }

  ngOnInit(): void {
  }


  login(){
    console.log('submiting')
    

    console.log(this.log);
      sessionStorage.Login = this.log.user.toString();
      //this.router.navigate(['/inicio']);
      window.location.href = `${environment.rutaDashboard}`;
    

  }

}

