import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SipuService } from 'src/app/services/sipu.service';
import { ActivatedRoute } from '@angular/router';
import { Dinvestigadores } from 'src/app/models/dinvestigadores';

@Component({
  selector: 'app-investig',
  templateUrl: './investig.component.html',
  styleUrls: ['./investig.component.css'],
})
export class InvestigComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  id: number;
  acc: string;
  investigador: Dinvestigadores;
  matricula: any;

  info: any;
  grado: any;
  libros: any;
  articulos: any;
  contrato: any;
  cuerpoAcad: any;
  investigadorArray: any[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private _ss: SipuService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);

    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
      this.acc = params['acc'];
      console.log('id: ' + this.id);

      if (this.id != 0) {
        this.blockUI.start('Cargando Investigador ...');
        this._ss.verInvestigador(this.id).subscribe(
          (investigador) => {
            this.investigador = investigador as Dinvestigadores;
            console.log('Investigador: ', this.investigador);

            this.matricula = this.investigador.dinv_matricula;
            this.traerDatos(this.matricula);

            this.blockUI.stop();
          }, (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  traerDatos(matricula: String) {
    this._ss.verInfoInvestigador(matricula).subscribe((info) => {
      this.info = info;
      console.log('INFO ****', this.info);
    });

    this._ss.verGradoInvestigador(matricula).subscribe((grado) => {
      this.grado = grado;
      console.log('GRADO ***', this.grado);
    });

    this._ss.verLibrosInvestigador(matricula).subscribe((libros) => {
      this.libros = libros;
      console.log('LIBRO***', this.libros);
    });

    this._ss.verArticulosInvestigador(matricula).subscribe((articulos) => {
      this.articulos = articulos;
      console.log('ARTICULOS ***', this.articulos);
    });

    this._ss.verContratosInvestigador(matricula).subscribe((contrato) => {
      this.contrato = contrato;
      console.log('CONTRATOS ***', this.contrato);
    });

    this._ss.verCuerposAcadInvestigador(matricula).subscribe((cuerpoAcad) => {
      this.cuerpoAcad = cuerpoAcad;
      console.log('cuerpoAcademico ***', this.cuerpoAcad);
    });
  }
}
