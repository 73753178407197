<app-header></app-header>

<block-ui>
<section class="page-title bg-title-ofacadem overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>OFERTA EDUCATIVA</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
					<li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
					<li class="breadcrumb-item active">Oferta Educativa</li>
				</ol>
			</div>
		</div>
	</div>
</section>

<!-- Counter Strat -->
<section class="ts_counter p-0">
	<div class="container-fluid">
		<div class="row facts-wrapper wow fadeInLeft text-center">
			
			<div class="facts one col-md-4 col-sm-6">
				<a [routerLink]="['/sipu']">
					<h3>ADMISION</h3>
				</a>
				
			</div>
		
			<div class="facts two col-md-4 col-sm-6">
				<a [routerLink]="['/sipu']"><h3>INSCRIPCION</h3></a>
				
			</div>

			<div class="facts three col-md-4 col-sm-6">
				<a [routerLink]="['/politicasreglamentos']"><h3>POLITICAS Y REGLAMENTOS</h3></a>
				
			</div>

		</div>
	</div>
	<!--/ Container end -->
</section>



<!-- Portfolio start -->
<section id="portfolio" class="portfolioOferta mb-20px">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 heading text-lg-left text-center">
				<span class="title-icon classic"><i class="fa fa-university"></i></span>
				<h2 class="title classic">Convocatorias</h2>
			</div>
			<!--Isotope filter start -->
			<!-- <div class="row"> -->
			<div class="col-lg-5 text-lg-right text-right">
				<div class="isotope-nav" data-isotope-nav="isotope">
					<select class="caja" (change)="applyFilter($event)">
						<option value="" selected> Selecciona una Facultad</option>
						<option *ngFor="let item of nomUres" [value]=item.vcon_descures> {{ item.vcon_descures }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<!-- Isotope filter end -->
	</div>

	<div class="container-fluid">
		<div class="row isotope" id="isotope" >

			<div class="col-sm-6 col-md-4 col-lg-3 web-design isotope-item" *ngFor="let item of convocatorias | paginate: { itemsPerPage: 12, currentPage: currentPage }">
				<div class="grid">
					<ng-container *ngIf="item.detalle.length != 0; else internalBlock">

						<div class="overlay"></div>
						<figure class="m-0 effect-oscar overlay-fondo">
							<img class="centered-and-cropped" width="200" height="400" src="https://www.sipu.ujed.mx/api/api/sipu/dnoticiasportal/noticiasConv/img/{{ item.vcon_convid }}" alt="" target="_blank" >
							<figcaption>
								<h3>{{item.vcon_descprog}}</h3>
								<a class="link icon-pentagon" [routerLink]="['/programainfo',item.vcon_convid]" target="_blank"><i class="fa fa-link"></i></a>
								<a class="view icon-pentagon" data-rel="prettyPhoto"
								href="https://www.sipu.ujed.mx/api/api/sipu/dnoticiasportal/noticiasConv/img/{{ item.vcon_convid }}" target="_blank"><i class="fa fa-search"></i></a>
							</figcaption>
						</figure>

					</ng-container>

					<ng-template #internalBlock >

						<figure class="m-0 effect-oscar">
							<img class="centered-and-cropped" *ngIf="item.conv_imagenHashCode" width="200" height="400" src="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ item.vcon_convid }}" alt="" target="_blank" >
							<img class="centered-and-cropped" *ngIf="!item.conv_imagenHashCode" width="200" height="400" src="./assets/images/notfound.jpg" alt="" >
							<figcaption>
								<h3>{{item.vcon_descprog}}</h3>
								<a class="link icon-pentagon"  [routerLink]="['/programainfo',item.vcon_convid]" target="_blank" ><i class="fa fa-link"></i></a>
								<a class="view icon-pentagon" data-rel="prettyPhoto"
								href="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ item.vcon_convid }}" target="_blank"><i class="fa fa-search"></i></a>
							</figcaption>
						</figure>

					</ng-template>
				</div>
			</div><!-- Isotope item end -->

			
		</div>

		
		<!-- Content row end -->
	</div><!-- Container end -->
</section><!-- Portfolio end -->

<div class="col-12 text-center">
	<nav class="d-flex justify-content-center">
	  <ul class="pagination">
		<li class="page-item" [class.disabled]="currentPage === 1">
			<a class="page-link" (click)="goToPreviousPage()" aria-label="Previous">
			  <span aria-hidden="true">&laquo;</span>
			  <span class="sr-only">Anterior</span>
			</a>
		  </li>
		<li class="page-item" *ngFor="let page of totalPages()" [class.active]="currentPage === page">
		  <a class="page-link"  (click)="currentPage = page">{{ page }}</a>
		</li>
		<li class="page-item" [class.disabled]="!totalPages().includes(currentPage + 1)">
			<a class="page-link" (click)="goToNextPage()" aria-label="Next">
			  <span aria-hidden="true">&raquo;</span>
			  <span class="sr-only">Siguiente</span>
			</a>
		  </li>
	  </ul>
	</nav>
  </div>

<!-- Parallax 1 start -->
<section class="parallax parallax6 mt-20px">
	<div class="parallax-overlay"></div>
	<div class="container">
		<div class="row">
			<div class="col-md-12 text-center">
				<h2>Te interesa alguna de las convocatorias?</h2>
				<h3>Da click para conocer los pasos a seguir, o dirigirte a SIPU, e inicia tu proceso de inscripción</h3>
				<p>
					<a class="btn btn-outline-white mr-2 " [routerLink]="['/sipu']" >Conoce los pasos a seguir</a>
					<a type="button" class="btn btn-danger text-white" href="https://www.sipu.ujed.mx/">	Sipu</a>
				</p>
			</div>
		</div>
	</div><!-- Container end -->
</section><!-- Parallax 1 end -->

<!-- Clients start -->
<section id="clients" class="clients">
	<div class="container">
		<div class="row wow fadeInLeft">
			<div id="client-carousel" class="col-sm-12 owl-carousel owl-theme text-center client-carousel">
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/pages/logo-anuies.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/pages/logo-comepo.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/pages/logo-conacyt.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/pages/logo-cumex.png" alt="client">
					</a>
				</figure>
				<figure class="m-0 item client_logo">
					<a href="#">
						<img src="./assets/images/pages/logo-prodep.png" alt="client">
					</a>
				</figure>
			</div><!-- Owl carousel end -->
		</div><!-- Main row end -->
	</div>
	<!--/ Container end -->
</section>
</block-ui>

<!--/ Clients end -->
<app-footer></app-footer>