<!-- Portfolio start -->


<section id="portfolio" class="portfolio mb-20px">
	

		<div class="container">
			<div class="row">
				<div class="col-lg-7 heading text-lg-left text-center">
					<span class="title-icon classic"><i class="fa fa-university"></i></span>
					<h2 class="title classic">Programas</h2>
				</div>
				<!--Isotope filter start -->
				<!-- <div class="row"> -->
				<div class="col-lg-5 text-lg-right text-right">
					<div class="isotope-nav" data-isotope-nav="isotope">
						<select class="caja" (change)="applyFilter($event)">
							<option value="" selected> Selecciona una Facultad</option>
							<option *ngFor="let item of nomUres" [value]=item.vcon_descures> {{ item.vcon_descures }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<!-- Isotope filter end -->
		</div>

		

		
		<div class="container-fluid">
			<block-ui>
			<div class="row isotope" id="isotope">

				

				<div class="col-sm-6 col-md-4 col-lg-3 web-design isotope-item" *ngFor="let item of convocatorias">
					<div class="grid">
						<ng-container *ngIf="item.detalle.length != 0; else internalBlock">

							<div class="overlay"></div>
							<figure class="m-0 effect-oscar overlay-fondo">
								<img class="centered-and-cropped" width="200" height="400"
									src="https://www.sipu.ujed.mx/api/api/sipu/dnoticiasportal/noticiasConv/img/{{ item.vcon_convid }}"
									alt="" target="_blank">
								<figcaption>
									<h3>{{item.vcon_descprog}}</h3>
									<a class="link icon-pentagon" [routerLink]="['/programainfo',item.vcon_convid]"
										target="_blank"><i class="fa fa-link"></i></a>
									<a class="view icon-pentagon" data-rel="prettyPhoto"
										href="https://www.sipu.ujed.mx/api/api/sipu/dnoticiasportal/noticiasConv/img/{{ item.vcon_convid }}"
										target="_blank"><i class="fa fa-search"></i></a>
								</figcaption>
							</figure>

						</ng-container>

						<ng-template #internalBlock>

							<figure class="m-0 effect-oscar">
								<img class="centered-and-cropped" *ngIf="item.conv_imagenHashCode" width="200"
									height="400"
									src="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ item.vcon_convid }}"
									alt="" target="_blank">
								<img class="centered-and-cropped" *ngIf="!item.conv_imagenHashCode" width="200"
									height="400" src="./assets/images/notfound.jpg" alt="">
								<figcaption>
									<h3>{{item.vcon_descprog}}</h3>
									<a class="link icon-pentagon" [routerLink]="['/programainfo',item.vcon_convid]"
										target="_blank"><i class="fa fa-link"></i></a>
									<a class="view icon-pentagon" data-rel="prettyPhoto"
										href="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ item.vcon_convid }}"
										target="_blank"><i class="fa fa-search"></i></a>
								</figcaption>
							</figure>

						</ng-template>
					</div>
				</div><!-- Isotope item end -->
			
			</div>
		</block-ui>
			<!-- Content row end -->
		</div><!-- Container end -->
	
		

</section><!-- Portfolio end -->


<!--/ Clients end -->