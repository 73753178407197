<app-header></app-header>



<section class="page-title bg-title-cursdip overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>Citar En Formato APA</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
				  <li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
				  <li class="breadcrumb-item active">DIPI</li>
				</ol>
			</div>
		</div>
	</div>
</section>


	<div class="row">
		<div class="col-md-12 text-center">
			<h2 class="title2"> HERRAMIENTAS PARA <span class="alternate">INVESTIGADORES</span>
				<span class="title-desc"></span>
			</h2>
		</div>
	</div><!-- Title row end -->
	
<section id="portfolio" class="portfolio portfolio-box">
	<div class="container">

		<div class="mb-4" align="center">
			<h3> Conoce como citar en APA 7 de una manera sencilla </h3>
		</div>

		<div class="row justify-content-center">
			<div class="col-sm-12 col-md-12 col-lg-12">

			<button type="button" class="btn botonapa btn-lg " style="background-color: #f7a729;" id="bt1" onclick="toogleContent('content1')">
				<i class="fa fa-book"></i> <br> Libro</button>
			<button type="button" class="btn botonapa btn-lg" style="background-color: #f3533a;" id="bt2" onclick="toogleContent('content2')">
				<i class="fa fa-file-text-o"></i> <br>Artículo (revista científica) </button>
			<button type="button" class="btn botonapa btn-lg" style="background-color: #2a5bbe;" id="bt2" onclick="toogleContent('content3')">
				<i class="fa fa-file-text-o"></i> <br>Artículos de divulgación </button>
			<button type="button" class="btn botonapa btn-lg" style="background-color: #999999;" id="bt2" onclick="toogleContent('content4')">
				<i class="fa fa-picture-o"></i> <br>Figuras, imágenes y gráficas </button>
			<button type="button" class="btn botonapa btn-lg" style="background-color: #6d2323;" id="bt2" onclick="toogleContent('content5')">
				<i class="fa fa-file-text"></i> <br>Tesis </button>
			<button type="button" class="btn botonapa btn-lg" style="background-color: #774783;" id="bt2" onclick="toogleContent('content6')">
				<i class="fa fa-file-text"></i> <br>Noticias y periódico </button>
			<button type="button" class="btn botonapa btn-lg" style="background-color: #0e0e71;" id="bt2" onclick="toogleContent('content7')">
				<i class="fa fa-file-text"></i> <br>Obras de consulta </button>


				<div id="content1" class="hidden text-center toogleContent">
					<div class="col-sm-12 col-md-12 col-lg-12 libro isotope-item">
    					<div class="grid">
							<h3>Libro</h3>
							<table class="table table-bordered">
									<thead>
						  			<tr>
										<th scope="col">Plantilla</th>
										<th scope="col">Ejemplo</th>
										<th scope="col">Mención de fuente de cita textual parentética</th>
						  			</tr>
									</thead>
								<tbody>
						  			<tr>
									<td>Apellido(s), A. A. (Año). Título del libro. Editorial.</td>
									<td>Apellido(s), A. A. (Año). Título del libro. Editorial.	Riso, W. (2018). Enamórate de ti. Planeta México.	(Riso, 2018, p. 34)</td>
									<td>(Riso, 2018, p. 34)</td>
						  			</tr>
								</tbody>
					  		</table>
    					</div>
					</div>
				</div>

<!-- 			<button type="button" class="btn botonapa btn-lg" style="background-color: #f3533a;" id="bt2" onclick="toogleContent('content2')">
				<i class="fa fa-file-text-o"></i> <br>Artículo (revista científica) </button> -->
				<div id="content2" class="hidden text-center toogleContent">
					<div class="col-sm-12 col-md-12 col-lg-12 articulo isotope-item">
    					<div class="grid">
						<h3>Artículo revista científica</h3>
	
							<table class="table table-bordered">
								<thead>
							 	 <tr>
									<th scope="col">Plantilla</th>
									<th scope="col">Ejemplo</th>
									<th scope="col">Mención de fuente de cita textual parentética</th>
							  	</tr>
								</thead>
								<tbody>
							  	<tr>
									<td>Autor, A. A. (Año). Título del artículo. Título de la publicación, volumen(número), páginas.</td>
									<td>Franco, Y. M. (2017). Rol del tutor en el contexto del aprendizaje virtual. Revista Scientific, 2(6), 270-285.<br>
									https://doi.org/10.29394/scientific. <br>
									issn.2542-2987.2017.2.6.14.270-285
									</td>
									<td>(Franco, 2018, p. 34)</td>
							 	</tr>
								</tbody>
						  	</table>
   						</div>
  					</div>
				</div>

		<!-- 	<button type="button" class="btn botonapa btn-lg" style="background-color: #2a5bbe;" id="bt2" onclick="hideShow('div3')">
				<i class="fa fa-file-text-o"></i> <br>Artículos de divulgación </button> -->
				<div id="content3" class="hidden  text-center toogleContent">
					<div class="col-sm-12 col-md-12 col-lg-12 artdiv isotope-item">
						<div class="grid">
							<h3>Artículos de divulgación</h3>
		
							<table class="table table-bordered">
								<thead>
								  <tr>
									<th scope="col">Plantilla</th>
									<th scope="col">Ejemplo</th>
									<th scope="col">Mención de fuente de cita textual parentética</th>
								  </tr>
								</thead>
								<tbody>
								  <tr>
									<td>Autor, A. A. (año, mes). Título del artículo. Título de la publicación,  volumen,número), páginas.</td>
									<td>Núñez, C. (2022, 2022). ¿Por qué se destruye la capa de ozono?. <br>
										National Geographic Latinoamérica, 42(2), 28-61.
									</td>
									<td>(Núñez, 2022, p. 23)</td>
								  </tr>
								</tbody>
							  </table>
						</div>
					  </div>
				</div>
<!-- 			<button type="button" class="btn botonapa btn-lg" style="background-color: #999999;" id="bt2" onclick="hideShow('div4')">
				<i class="fa fa-picture-o"></i> <br>Figuras, imágenes y gráficas </button> -->
				<div id="content4" class="hidden  text-center toogleContent">
					<div class="grid">
						<h3>Figuras, imágenes y gráficas</h3>
						<p>Al incluir en nuestro trabajo figuras, es decir, cualquier imagen o ilustración que se incluya en el texto (imágenes, fotografías, dibujos, mapas, gráficas) que no sean de nuestra autoría, debemos agregar la correspondiente referencia bibliográfica de la fuente de donde obtuvimos dicha figura: </p>
					</div>
				</div>
				
<!-- 			<button type="button" class="btn botonapa btn-lg" style="background-color: #6d2323;" id="bt2" onclick="hideShow('div5')">
				<i class="fa fa-file-text"></i> <br>Tesis </button> -->
				<div id="content5" class="hidden  text-center toogleContent">
					<div class="col-sm-12 col-md-12 col-lg-12 tesis isotope-item">
						<div class="grid">
							<h3>Tesis</h3>
		
							<table class="table table-bordered">
								<thead>
								  <tr>
									<th scope="col">Plantilla</th>
									<th scope="col">Ejemplo</th>
									<th scope="col">Mención de fuente de cita textual parentética</th>
								  </tr>
								</thead>
								<tbody>
								  <tr>
									<td>Apellido(s), A. A., (Año). Título de la tesis [Tipo de tesis sin publicar]. Nombre de la Institución.</td>
									<td>Domínguez Varela, J.G. (2022). El reto de la educación a distancia en tiempos de pandemia 
										[Tesis de Maestría sin publicar]. Universidad Juárez del Estado de Durango.
									</td>
									<td>Domínguez Varela (2022), p.128)</td>
								  </tr>
								</tbody>
							  </table>
						</div>
					  </div>
				</div>

				<div id="content6" class="hidden  text-center toogleContent">
					<div class="col-sm-12 col-md-12 col-lg-12 noticias isotope-item">
						<div class="grid">
							<h3>Noticias y periódico</h3>
		
							<table class="table table-bordered">
								<thead>
								  <tr>
									<th scope="col">Plantilla</th>
									<th scope="col">Ejemplo</th>
									<th scope="col">Mención de fuente de cita textual parentética</th>
								  </tr>
								</thead>
								<tbody>
								  <tr>
									<td>Autor, A. A. (año, día de mes). Título del artículo. Título del periódico, páginas.</td>
									<td>Muñoz, H. (2022, 24 de septiembre). Biden repunta rumbo a elecciones intermedias en EU. Milenio, p. Análisis.
									</td>
									<td>Muñoz (2022), p. Análisis.</td>
								  </tr>
								</tbody>
							  </table>
						</div>
					  </div>
				</div>

<!-- 			<button type="button" class="btn botonapa btn-lg" style="background-color: #0e0e71;" id="bt2" onclick="hideShow('div7')">
				<i class="fa fa-file-text"></i> <br>Obras de consulta </button> -->
				<div id="content7" class="hidden  text-center toogleContent">
					<div class="col-sm-12 col-md-12 col-lg-12 obras isotope-item">
						<div class="grid">
							<h3>Obras de consulta</h3>
		
							<table class="table table-bordered">
								<thead>
								  <tr>
									<th scope="col">Plantilla</th>
									<th scope="col">Ejemplo</th>
									<th scope="col">Mención de fuente de cita textual parentética</th>
								  </tr>
								</thead>
								<tbody>
								  <tr>
									<td>Nombre del grupo u organización. (s.f.). Entrada o concepto consultado. En Título del diccionario o enciclopedia. Recuperado el día de me del año, de URL</td>
									<td>American Psychological Association. <br>
										(s.f.). APA dictionary of psychology. Recuperado el 14 de junio de 2019 de http://dictionaty.apa.org/
									</td>
									<td>American Psychological Association. (s.f.).</td>
								  </tr>
								</tbody>
							  </table>
						</div>
					  </div>
				</div>
			</div>
		</div>
	</div>
</section>


<app-footer></app-footer>
