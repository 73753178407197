import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SipuService } from '../services/sipu.service';

@Component({
  selector: 'app-investigadores',
  templateUrl: './investigadores.component.html',
  styleUrls: ['./investigadores.component.css']
})
export class InvestigadoresComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  investigadores: any;
  candidato:any;
  sni1:any;
  sni2:any;
  sni3:any;

  constructor(private _ss: SipuService, private http: HttpClient) { }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.blockUI.start('Cargando investigadores');
    this._ss.listarInvestigador().subscribe(investigadores => {
      this.investigadores = investigadores;  
      console.log("INVESIGADORES" , this.investigadores);

      this.candidato = this.investigadores.filter(i => i.vinv_nivel === 'CI');
      this.sni1= this.investigadores.filter(i => i.vinv_nivel === '1');
      this.sni2= this.investigadores.filter(i => i.vinv_nivel === '2');
      this.sni3= this.investigadores.filter(i => i.vinv_nivel === '3');

       this.blockUI.stop(); 
    },
    error => {
      console.log(error);
    });
  
    
  }

}
