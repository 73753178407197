<app-header></app-header>
<block-ui>
<section class="page-title bg-title-res overlay-fondo">
  <div class="container">
    <div class="row">
      <div class="col-12 text-left">
        <div class="title">
          <h2>RESULTADOS DIPI</h2>
        </div>
        <ol class="breadcrumb justify-content-left p-0 m-0">
          <li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
          <li class="breadcrumb-item active">RESULTADOS</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<div class="container">
  <div class="row">
    <div class="col-md-12 heading text-center">
      <h2 class="title2">Resultados de Posgrado e Investigación
        <span class="title-desc"></span>
      </h2>
    </div>
  </div><!-- Title row end -->

  <div class="row about-wrapper-top flex-column-reverse flex-lg-row">
    <div class="col-lg-6 ts-padding about-message">
      <h3>Sistema Nacional de Investigadores </h3>
      <p>El SNI tiene por objeto promover y fortalecer,
        a través de la evaluación, la calidad de la investigación científica
        y tecnológica, y la innovación que se produce en el país, contribuye
        a la formación y consolidación de investigadores con conocimientos científicos
        y tecnológicos del más alto nivel como un elemento fundamental para incrementar
        la cultura, productividad, competitividad y el bienestar social.</p>

		<br>
    <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(82, 134, 152); font-size: 20px; padding: 10px;">{{ estadisticas['21']?.dest_nombre }}:</span>
  </div>
  <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 20px; padding: 10px;">{{ estadisticas['21']?.dest_valor }}</span>
  </div>
    <br>
    <br>
    <ng-container *ngIf="estadisticas['21']?.dest_descripcion">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 12px; padding: 10px;">{{ estadisticas['21']?.dest_descripcion }}</span>
    </ng-container>
    
    </div>
    <!--/ About message end -->
    <div class="col-lg-6 ts-padding about-img"
      style="height:374px;background:url(./assets/images/dipi/SNI.jpg) 50% 50% / cover no-repeat;">
    </div>
    <!--/ About image end -->
  </div>
  <!--/ Content row end -->

  <div class="row about-wrapper-bottom mt-5 mt-lg-0">
    <div class="col-lg-6 ts-padding about-img"
      style="height:374px;background:url(./assets/images/dipi/Perfildeseable.jpg) 50% 50% / cover no-repeat;">
    </div>
    <!--/ About image end -->
    <div class="col-lg-6 ts-padding about-message">
      <h3>Perfiles deseables </h3>



	  <br>
	  <br>
    <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(82, 134, 152); font-size: 20px; padding: 10px;">{{ estadisticas['22']?.dest_nombre }}:</span>
  </div>
  <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 20px; padding: 10px;">{{ estadisticas['22']?.dest_valor }}</span>
  </div>
    <br>
    <br>
    <ng-container *ngIf="estadisticas['22']?.dest_descripcion">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 12px; padding: 10px;">{{ estadisticas['22']?.dest_descripcion }}</span>
    </ng-container>
    </div>
    <!--/ About message end -->
  </div>

  <div class="row about-wrapper-top flex-column-reverse flex-lg-row">
    <div class="col-lg-6 ts-padding about-message">
      <h3>Cuerpos académicos</h3>
      <p>
        Contar con un sistema de Investigación y Posgrado administrados de manera ágil,
        eficiente y automatizada, sustentada en normas y procedimientos actualizados manteniendo
        al personal capacitado para la gestión, integración y desempeño de sus labores.</p>

	  <br>
    <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(82, 134, 152); font-size: 20px; padding: 10px;">{{ estadisticas['23']?.dest_nombre }}:</span>
  </div>
  <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 20px; padding: 10px;">{{ estadisticas['23']?.dest_valor }}</span>
  </div>
    <br>
    <br>
    <ng-container *ngIf="estadisticas['23']?.dest_descripcion">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 12px; padding: 10px;">{{ estadisticas['23']?.dest_descripcion }}</span>
    </ng-container>
    </div>
    <!--/ About message end -->
    <div class="col-lg-6 ts-padding about-img"
      style="height:374px;background:url(./assets/images/dipi/Cuerposacadémicos.jpg) 50% 50% / cover no-repeat;">
    </div>
    <!--/ About image end -->
  </div>

  <div class="row about-wrapper-bottom mt-5 mt-lg-0">
    <div class="col-lg-6 ts-padding about-img"
      style="height:374px;background:url(./assets/images/dipi/IncubadoraCA.jpg) 50% 50% / cover no-repeat;">
    </div>
    <!--/ About image end -->
    <div class="col-lg-6 ts-padding about-message">
      <h3>Incubadora de cuerpos académicos</h3>

	  <br>
	  <br>
    <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(82, 134, 152); font-size: 20px; padding: 10px;">{{ estadisticas['24']?.dest_nombre }}:</span>
  </div>
  <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 20px; padding: 10px;">{{ estadisticas['24']?.dest_valor }}</span>
  </div>
  <br>
  <br>
    <ng-container *ngIf="estadisticas['24']?.dest_descripcion">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 12px; padding: 10px;">{{ estadisticas['24']?.dest_descripcion }}</span>
    </ng-container>

    </div>
  </div>

  <div class="row about-wrapper-top flex-column-reverse flex-lg-row">
    <div class="col-lg-6 ts-padding about-message">
      <h3>Sistema Nacional de Posgrados</h3>
      <p>
        Fomenta la transformación continua y el aseguramiento de la pertinencia de los posgrados
      </p>

	  <br>
	  <br>
    <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(82, 134, 152); font-size: 20px; padding: 10px;">{{ estadisticas['28']?.dest_nombre }}:</span>
  </div>
  <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 20px; padding: 10px;">{{ estadisticas['28']?.dest_valor }}</span>
  </div> <br>
    <br>
    <ng-container *ngIf="estadisticas['28']?.dest_descripcion">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 12px; padding: 10px;">{{ estadisticas['28']?.dest_descripcion }}</span>
    </ng-container>
    </div>
    <!--/ About message end -->
    <div class="col-lg-6 ts-padding about-img"
      style="height:374px;background:url(./assets/images/dipi/SNP.jpg) 50% 50% / cover no-repeat;">
    </div>
    <!--/ About image end -->
  </div>

  <div class="row about-wrapper-bottom mt-5 mt-lg-0">
    <div class="col-lg-6 ts-padding about-img"
      style="height:374px;background:url(./assets/images/dipi/Proyectosfinanciamientoexterno.jpg) 50% 50% / cover no-repeat;">
    </div>
    <!--/ About image end -->
    <div class="col-lg-6 ts-padding about-message">
      <h3>Proyectos aprobados con financiamiento externo </h3>

	  <br>
	  <br>
    <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(82, 134, 152); font-size: 20px; padding: 10px;">{{ estadisticas['26']?.dest_nombre }}:</span>
  </div>
  <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 20px; padding: 10px;">{{ estadisticas['26']?.dest_valor }}</span>
  </div>
  
  <br>
    <br>
    <ng-container *ngIf="estadisticas['26']?.dest_descripcion">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 12px; padding: 10px;">{{ estadisticas['26']?.dest_descripcion }}</span>
    </ng-container>
    </div>
  </div>

  <div class="row about-wrapper-top flex-column-reverse flex-lg-row">
    <div class="col-lg-6 ts-padding about-message">
      <h3>SEI</h3>
      <p>
        Fomenta la transformación continua y el aseguramiento de la pertinencia de los posgrados
      </p>

	  
	  <br>
	  <br>

    <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(82, 134, 152); font-size: 20px; padding: 10px;">{{ estadisticas['27']?.dest_nombre }}:</span>
    </div>
    <div class="estadisticas-item">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 20px; padding: 10px;">{{ estadisticas['27']?.dest_valor }}</span>
    </div> 
  
  <br>
  <br>
    <ng-container *ngIf="estadisticas['27']?.dest_descripcion">
      <span style="color: white; background-color: rgb(71, 113, 127); font-size: 12px; padding: 10px;">{{ estadisticas['27']?.dest_descripcion }}</span>
    </ng-container>
    
    </div>
    <div class="col-lg-6 ts-padding about-img"
      style="height:374px;background:url(./assets/images/dipi/noticias.JPG) 50% 50% / cover no-repeat;">
    </div>
    <!--/ About image end -->
  </div>


  <!--/ Content row end -->
  <!-- Company Profile -->
</div>

<section class="ts_counter p-0">
  <div class="container-fluid">
    <div class="row facts-wrapper wow fadeInLeft text-center">
      <div class="facts one col-lg-4 col-md-4 col-sm-6">
        <span class="facts-icon"><i class="fa fa-institution"></i></span>
        <div class="facts-num">
          <span class="counter">{{ estadisticas['29']?.dest_valor }}</span>
        </div>
        <h3>{{ estadisticas['29']?.dest_nombre }}</h3>
        <small>{{ estadisticas['29']?.dest_descripcion }}</small>
      </div>
      <div class="facts one col-lg-4 col-md-4 col-sm-6">
        <span class="facts-icon"><i class="fa fa-institution"></i></span>
        <div class="facts-num">
          <span class="counter">{{ estadisticas['30']?.dest_valor }}</span>
        </div>
        <h3>{{ estadisticas['30']?.dest_nombre }}</h3>
        <small>{{ estadisticas['30']?.dest_descripcion }}</small>
      </div>
      <div class="facts one col-lg-4 col-md-4 col-sm-6">
        <span class="facts-icon"><i class="fa fa-institution"></i></span>
        <div class="facts-num">
          <span class="counter">{{ estadisticas['31']?.dest_valor }}</span>
        </div>
        <h3>{{ estadisticas['31']?.dest_nombre }}</h3>
        <small>{{ estadisticas['31']?.dest_descripcion }}</small>
      </div>
    </div>
  </div>

</section>

</block-ui>