<app-header></app-header>
<!-- <block-ui> -->
<block-ui>
	<section class="page-title bg-title-noticias overlay-fondo">
		<div class="container">
			<div class="row">
				<div class="col-12 text-left">
					<div class="title">
						<h2>{{convocatoria?.vcon_descprog}} </h2>
					</div>
					<ol class="breadcrumb justify-content-left p-0 m-0">
						<li class="breadcrumb-item"><a [routerLink]="['/ofertaeduca']">Regresar</a></li>
						<li class="breadcrumb-item active">{{convocatoria?.vcon_descures}}</li>
					</ol>
				</div>
			</div>
		</div>
	</section>

	<!-- Portfolio item start -->
	<div class="container">
		<div class="row">
			<div class="col-md-12 heading text-center">
				<h2 class="titleConv">{{convocatoria?.vcon_descrip}}
					<span class="title-desc">{{convocatoria?.vcon_descures}}</span>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-xs-12 mt-1">
				<img src="https://www.sipu.ujed.mx/api/api/sipu/convocatoria/img/{{ convocatoria?.vcon_convid }}" alt=""
					style="width: 100%; " />
			</div>

			<!-- sidebar start -->
			<div class="col-lg-6 col-xs-12 mt-1">

				<div class="sidebar">
					<div class="portfolio-desc">
						<h4>Dirigido: </h4>
						<p>{{convocatoria?.vcon_dirigido}}</p>

						<br/>
						<h4> Objetivo: </h4>
						<p>{{convocatoria?.vcon_objetivo}}</p>


						<br/>
						<h4>Contacto: </h4>
						<ul class="list-group">
							<li class="list-group-item"> <b>Telefono: </b> 
								<div>
									<i class="fa fa-phone"></i>
									{{contacto[0].ures_tel1}}
								</div>
							</li>

							<li class="list-group-item"> <b>Correo: </b>
								<div>
									<i class="fa fa-envelope"></i>
									 {{contacto[0].ures_email1}}
								</div>
							</li>
							<li class="list-group-item"> <b>Responsable: </b>
								<div>
									<i class="fa fa-user"></i>
									{{contacto[0].ures_nomrespo1}}
								</div>
							</li>
						</ul>

						<p><a href="https://www.sipu.ujed.mx/" class="project-btn btn btn btn-outline-danger">Conoce más
								información en SIPU</a></p>
					</div>
				</div>
			</div>
			<!-- sidebar end -->
		</div><!-- Portfolio item row end -->
	</div><!-- Container end -->

	<div class="hr1" style="margin-bottom:50px;"></div>
	
	<block-ui>
		<!-- </block-ui> -->
		<app-footer></app-footer>